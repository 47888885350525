import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
    namespaced: true,
    state() {
        return {
            collections:[
                {
                    id: 2,
                    thumb: 'https://picsum.photos/id/11/500/300',
                    title: 'Nome della collezione',
                    description: 'Lorem 3 ipsum dolor sit amet consectetur adipisicing elit. Aliquid ratione optio corrupti officia. Voluptatem recusandae labore iste nulla saepe dolorum, quos excepturi blanditiis, eos repellat error eligendi esse ad optio!',
                    updates: [1,2,3],

                    /* countries: [
                        {name: "italia", iso_code:'it'},
                        {name: "germania", iso_code:'de'},
                    ],
                    category: ['category_1','category_2','category_3'],
                    parametri: ['parametri_1','parametri_3'], */
                },
            ]
        };
    },
    mutations,
    getters,
    actions,
};
