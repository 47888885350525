<template>
   <div class="latest-news">
     <div data-aos="fade-up" class="container overflow-hidden">
       <div class="latest-news--header-box">
         <h4 class="latest-new--title">{{ $t("home.news.title") }}</h4>
         <p class="latest-new--subtitle">{{ $t("home.news.subtitle") }}</p>
       </div>
       <carousel
            class="carousel carousel--top-news"
            :loop="false"
            :rewind="true"
            :dots="true"
            :nav="false"
            :margin="18"
            :autoplay="true"
            :autoplayHoverPause="true"
            
            :stagePadding="0"
            :startPosition="1"
            :responsive="{
              0:{
                  items: 1,
              },
              840:{
                  items: 3,
              }
            }"
        >  
          <card-news v-for="item_new in news" :key="item_new.id" :news="item_new"></card-news>
        </carousel>
     </div>
   </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import CardNews from "@/modules/news/components/CardNews.vue";
export default {
  components: {
    carousel,
    CardNews
  },
  computed: {
    news() {
      let t = this.$store.getters['news/news'];
      return t
    }
  },
};
</script>

<style lang="scss" scoped>
/*  */
.latest-news{
  padding: 3rem 0;
  background: #f5f5f5;
  box-shadow: inset 0px -5px 4px rgba(black, 5%);

  &--header-box{
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid black;
  }

  &--title{
    color: $primary;
    font-weight: 400;
  }
  &--subtitle{
  }
}
</style>