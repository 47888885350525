<template>
    <div class="container shadow-pannel my-section shadow-md">

        <div class="p-[5rem_3rem] ">
            <v-row>

                <v-col cols="12">
                    <div class="form-group">
                        <v-select
                            v-model="value_comodity"
                            label="Comodity"
                            :items="comodity"
                            outlined
                            dense
                            hide-selected
                        >
                        </v-select>
                    </div>
                </v-col>

                <!-- date_start -->
                <v-col cols="6">
                    <div class="form-group">
                        <v-menu
                            v-model="menu_start"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dateStartMoment"
                                    label="Data inizio di entrata in vigore"
                                    outlined
                                    dense
                                    readonly
                                    append-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                </v-text-field>

                            </template>
                            <v-date-picker
                                v-model="date_start"
                                @input="menu_start = false"
                                :first-day-of-week="1"
                                locale="it"
                            ></v-date-picker>
                        </v-menu>
                    </div>
                </v-col>
                <!-- date_end -->
                <v-col cols="6">
                    <div class="form-group">
                        <v-menu
                            v-model="menu_end"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dateEndMoment"
                                    label="Data fine di entrata in vigore"
                                    outlined
                                    dense
                                    readonly
                                    append-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                </v-text-field>

                            </template>
                            <v-date-picker
                                v-model="date_end"
                                @input="menu_end = false"
                                :first-day-of-week="1"
                                locale="it"
                            ></v-date-picker>
                        </v-menu>
                    </div>
                </v-col>
                <!-- countries -->
                <v-col cols="6">
                    <div class="form-group">
                        <v-combobox
                            v-model="value_countries"
                            label="country"
                            :items="countries"
                            item-text="name"
                            dense
                            hide-selected
                            multiple
                            outlined
                            small-chips
                        >
                        <template #selection="{ item }">
                            <v-chip
                                class="color-white"
                                color="primary"
                                small
                            >
                                {{item.name}}
                            </v-chip>
                        </template>
                            <template v-slot:item="{  item }">

                                <div class="flex gap-5">
                                    <div><country-flag :country='item.iso_code' /></div>
                                    <p class="mt-[8px]">{{item.name}}</p>
                                </div>

                            </template>
                        </v-combobox>
                        
                    </div>
                </v-col>
                <!-- category -->
                <v-col cols="6">
                    <div class="form-group">
                        <v-combobox
                            v-model="value_category"
                            label="Categoria merce"
                            :items="category"
                            dense
                            hide-selected
                            multiple
                            outlined
                            small-chips
                        >
                        <template #selection="{ item }">
                            <v-chip
                                class="color-white"
                                color="primary"
                                small
                            >
                                {{item}}
                            </v-chip>
                        </template>
                        </v-combobox>
                    </div>
                </v-col>
            </v-row>

            <div class="flex justify-center mt-3">
                <v-btn
                    class="px-6"
                    color="primary"
                    elevation="3"
                    @click="searchAgenda"
                    outlined
                >
                    Cerca nell'archivio
                </v-btn>
            </div>
        </div>

    </div>
</template>

<script>
import moment from 'moment';

export default {
    data() {
        return {
            comodity: [
                "Regulatory Update Online | Food",
                "Regulatory Update Online | No Food",
            ],
            value_comodity: null,
            countries: [
                {name: "italia", iso_code:'it'},
                {name: "germania", iso_code:'de'},
                {name: "francia", iso_code:'fr'},
            ],
            value_countries: null,
            category: [
                "Categoria 1",
                "Categoria 2",
                "Categoria 3",
                "Categoria 4",
            ],
            value_category: null,
            
            date_start: null,
            date_end: null,
            menu_start: false,
            menu_end: false,
        }
    },
    computed: {
      dateStartMoment () {
        return this.date_start ? moment(this.date_start).format('DD/MM/YYYY') : ''
      },
      dateEndMoment () {
        return this.date_end ? moment(this.date_end).format('DD/MM/YYYY') : ''
      },
    },
    methods: {
        searchAgenda() {
            if(
                this.value_comodity != null &&
                this.date_start != null &&
                this.date_end != null &&
                this.value_countries.length != 0 &&
                this.value_category.length != 0
            ){
                console.log("sub");
            }
        }
    },
}
</script>

<style lang="scss" scoped>
/*  */
</style>