<template>
<div class="position-relative">
    <intro-pages :title="'Regulatory Update FOOD & Aggiornamenti Normativi'">
        <p>
            Regulatory Update è lo strumento di aggiornamento normativo di Mérieux NutriSciences che si avvale del monitoraggio quotidiano delle Gazzette Ufficiali elettroniche di 76 Paesi. I filtri sono a disposizione per selezionare solo gli aggiornamenti di tuo interesse.
            Nota: gli aggiornamenti dal Venezuela non sono disponibili con regolarità da gennaio 2020. Il monitoraggio viene comunque effettuato quotidianamente.
        </p>
    </intro-pages>
    <div class="my-section">
        <list-updates data-aos="fade-up"></list-updates>
    </div>
</div>
</template>

<script>
import IntroPages from "@/components/IntroPages.vue"
import ListUpdates from "@/components/ListUpdates.vue"

export default {
    components: {
        IntroPages,
        ListUpdates,
    },
}
</script>

<style lang="scss" scoped>
/*  */
</style>