/* import * as types from "./mutation-types"; */

export default {
    addItem(state, new_item){
        if(new_item){
            state.journals.push(new_item)
            return
        }
        console.log("ERRORE MUTATION catalogs -> addItem");
        return
    },
    replaceItem(state, {id, new_value}){
        if(id && new_value){
            let index = state.journals.findIndex(el => el.id == id)
            state.journals[index] = new_value
            return
        }
        console.log("ERRORE MUTATION journals -> replaceItem");
        return
    },
    deleteItem(state, item){
        if(item){
            state.journals = state.journals.filter(el => el.id != item.id)
            return
        }
        console.log("ERRORE MUTATION NOTIFICATION -> replaceItem");
        return
    },
};
