import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters.js";
import mutations from "./mutations.js";
import actions from "./actions.js";

import storePlugin from "@/plugins/storePlugin";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [storePlugin],
  state: {
    authenticated_profile: null,
    acl_map: {},
    did_auto_logout: false,
    loadingPage: true,
    user: {
      name: 'Francesco',
      last: 'Bonafede',
      email: 'f.bona99@gmail.com',
      phone: '3401234567',
      cell: '+39 3401234567',
      address: 'Via Nessuno n°0',

      type_account: 'advanced',
      agency: 'Pictastudio',
      allert_max: '5',
      subscriber_countries: [1,2,4,5]
    },
  },
  getters,
  mutations,
  actions
});
