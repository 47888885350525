<template>
   <div class="info-account">
      
       <div class="container my-section">
           <h2 class="color-primary font-bold">Informazioni profilo:</h2>
           <div v-if="!modify_mode" class="shadow-pannel p-[3rem]">
               <v-row>
                   <v-col cols="4">
                       <div class="info-account--campo">
                           <p class="info-account--label">Nome utente:</p>
                           <p class="info-account--value">{{user_copy.name}}</p>
                       </div>
                   </v-col>
                   <v-col cols="4">
                       <div class="info-account--campo">
                           <p class="info-account--label">Cognome utente:</p>
                           <p class="info-account--value">{{user_copy.last}}</p>
                       </div>
                   </v-col>
                   <v-col cols="4">
                       <div class="info-account--campo">
                           <p class="info-account--label">E-mail:</p>
                           <p class="info-account--value">{{user_copy.email}}</p>
                       </div>
                   </v-col>
                   <v-col cols="4">
                       <div class="info-account--campo">
                           <p class="info-account--label">Telefono:</p>
                           <p class="info-account--value">{{user_copy.phone}}</p>
                       </div>
                   </v-col>
                   <v-col cols="4">
                       <div class="info-account--campo">
                           <p class="info-account--label">Telefono cellulare</p>
                           <p class="info-account--value">{{user_copy.cell}}</p>
                       </div>
                   </v-col>
                   <v-col cols="4">
                       <div class="info-account--campo">
                           <p class="info-account--label">Indirizzo:</p>
                           <p class="info-account--value">{{user_copy.address}}</p>
                       </div>
                   </v-col>
                   <v-col cols="4">
                       <div class="info-account--campo">
                           <p class="info-account--label">Nome Azienda:</p>
                           <p class="info-account--value">{{user_copy.agency}}</p>
                       </div>
                   </v-col>
               </v-row>
               <div class="flex justify-end mt-3">
                   <v-btn
                        class="color-white"
                        color="primary"
                        elevation="2"
                       @click="modify_mode = true"
                    >
                        <v-icon left>
                            mdi-pencil
                        </v-icon>
                        Modifica
                    </v-btn>
               </div>
           </div>
           <div v-else class="shadow-pannel p-[3rem]">
               <v-row>
                   <v-col cols="4">
                       <div class="form-group mb-0">
                            <v-text-field
                            class="form-group--input"
                            label="Nome utente:"
                            v-model="user_copy.name"
                            outlined
                            dense
                            hide-details="auto"
                            ></v-text-field>
                        </div>
                   </v-col>
                   <v-col cols="4">
                       <div class="form-group mb-0">
                            <v-text-field
                            class="form-group--input"
                            label="Cognome utente:"
                            v-model="user_copy.last"
                            outlined
                            dense
                            hide-details="auto"
                            ></v-text-field>
                        </div>
                   </v-col>
                   <v-col cols="4">
                       <div class="form-group mb-0">
                            <v-text-field
                            class="form-group--input"
                            label="E-mail:"
                            v-model="user_copy.email"
                            outlined
                            dense
                            hide-details="auto"
                            ></v-text-field>
                        </div>
                   </v-col>
                   <v-col cols="4">
                       <div class="form-group mb-0">
                            <v-text-field
                            class="form-group--input"
                            label="Telefono:"
                            v-model="user_copy.phone"
                            outlined
                            dense
                            hide-details="auto"
                            ></v-text-field>
                        </div>
                   </v-col>
                   <v-col cols="4">
                       <div class="form-group mb-0">
                            <v-text-field
                            class="form-group--input"
                            label="Telefono cellulare"
                            v-model="user_copy.cell"
                            outlined
                            dense
                            hide-details="auto"
                            ></v-text-field>
                        </div>
                   </v-col>
                   <v-col cols="4">
                       <div class="form-group mb-0">
                            <v-text-field
                            class="form-group--input"
                            label="Indirizzo:"
                            v-model="user_copy.address"
                            outlined
                            dense
                            hide-details="auto"
                            ></v-text-field>
                        </div>
                   </v-col>
                   <v-col cols="4">
                       <div class="form-group mb-0">
                            <v-text-field
                            class="form-group--input"
                            label="Nome Azienda:"
                            v-model="user_copy.agency"
                            outlined
                            dense
                            hide-details="auto"
                            ></v-text-field>
                        </div>
                   </v-col>
                   
               </v-row>
               <div class="flex justify-end gap-3 mt-3">
                   <v-btn
                        class="color-white"
                        color="green"
                        elevation="2"
                        @click="saveUserData"
                    >
                        <v-icon left>
                            mdi-content-save
                        </v-icon>
                        Salva
                    </v-btn>
                    <v-btn
                        class="color-white"
                        color="red"
                        elevation="2"
                        @click="modify_mode = false"
                    >
                        <v-icon left>
                            mdi-cancel
                        </v-icon>
                        Annulla
                    </v-btn>
               </div>
           </div>
       </div>


   </div>
</template>

<script>
export default {
    props: ['user'],
    data() {
        return {
            modify_mode: false,
            /* user_copy: null, */
        }
    },
    computed: {
        user_copy() {
            return JSON.parse(JSON.stringify(this.user))
        }
    },
    methods: {
        async saveUserData() {
            this.mainShowLoading()
            await this.$store.dispatch('editUser',this.user_copy)
            this.mainHideLoading()

            this.modify_mode = false
        }
    },
    /* created () {
        this.user_copy = JSON.parse(JSON.stringify(this.user))
    }, */
};
</script>

<style lang="scss" scoped>
/*  */
.info-account{
    &--campo{

    }
    &--label:not(.not){
        position: relative;
        width: fit-content;
        font-weight: bold;
        color: $primary;
        margin-bottom: 1rem;
        &::after{
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            height: 2px;
            width: calc(100% + 30px);
            background: $primary;
        }
    }
    &--value{

    }
}
</style>