/* import * as types from "./mutation-types"; */

export default {
    addItem(state, new_item){
        if(new_item){
            state.countries.push(new_item)
            return
        }
        console.log("ERRORE MUTATION NOTIFICATION -> addItem");
        return
    },
    replaceItem(state, {id, new_value}){
        if(id && new_value){
            let index = state.countries.findIndex(el => el.id == id)
            state.countries[index] = new_value
            return
        }
        console.log("ERRORE MUTATION NOTIFICATION -> replaceItem");
        return
    },
    deleteItem(state, item){
        if(item){
          state.countries = state.countries.filter(el => el.id != item.id)
          return
        }
        console.log("ERRORE MUTATION NOTIFICATION -> replaceItem");
        return
    },
};
