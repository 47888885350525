import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
    namespaced: true,
    state() {
        return {
            
            itemsTest:[],
            updates: [
                {
                    id: 1,
                    comodity: ['food'],
                    preregulatory: true,
                    title: 'Francia - Informazioni ( 15settembre ) sulle qualità e caratteristiche ambientali di prodotti che generano Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano',
                    text: 'Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano',
                    date: "2022-09-15T00:00:00+01:00",
                    countries: [
                        { id: 2, label: "Germania", value: { name: "germania", iso_code:'de' }},
                    ],
                },
                {
                    id: 2,
                    comodity: ['consumer'],
                    preregulatory: false,
                    title: 'Francia - Informazioni ',
                    date: "2022-08-15T00:00:00+01:00",
                    countries: [
                        { id: 1, label: "Italia", value: { name: "italia", iso_code:'it' }},
                        { id: 2, label: "Germania", value: { name: "germania", iso_code:'de' }},
                        { id: 10, label: "Svizzera", value: { name: "svizzera", iso_code:'ch' }},
                        { id: 3, label: "Francia", value: { name: "francia", iso_code:'fr' }},
                    ],
                },
                {
                    id: 3,
                    comodity: ['food'],
                    preregulatory: false,
                    title: 'Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano 3',
                    text: 'Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano 3',
                    date: "2022-08-15T00:00:00+01:00",
                    countries: [
                        { id: 1, label: "Italia", value: { name: "italia", iso_code:'it' }},
                        { id: 8, label: "Malesia", value: { name: "malesia", iso_code:'mys' }},
                    ],
                },
                {
                    id: 4,
                    comodity: ['consumer'],
                    preregulatory: true,
                    title: 'Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano',
                    text: 'Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano',
                    date: "2022-08-15T00:00:00+01:00",
                    countries: [
                        { id: 2, label: "Germania", value: { name: "germania", iso_code:'de' }},
                    ],
                },
                {
                    id: 5,
                    comodity: ['consumer'],
                    preregulatory: false,
                    title: 'Francia - Informazioni ',
                    text: 'Francia - Informazioni ',
                    date: "2022-08-15T00:00:00+01:00",
                    countries: [
                        { id: 3, label: "Francia", value: { name: "francia", iso_code:'fr' }},
                    ],
                },
                {
                    id: 6,
                    comodity: ['food'],
                    preregulatory: false,
                    title: 'Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano 3',
                    text: 'Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano 3',
                    date: "2022-08-15T00:00:00+01:00",
                    countries: [
                        { id: 1, label: "Italia", value: { name: "italia", iso_code:'it' }},
                    ],
                },
                {
                    id: 7,
                    comodity: ['consumer'],
                    preregulatory: true,
                    title: 'Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano',
                    text: 'Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano',
                    date: "2022-08-15T00:00:00+01:00",
                    countries: [
                        { id: 1, label: "Italia", value: { name: "italia", iso_code:'it' }},
                        { id: 2, label: "Germania", value: { name: "germania", iso_code:'de' }},
                        { id: 10, label: "Svizzera", value: { name: "svizzera", iso_code:'ch' }},
                        { id: 3, label: "Francia", value: { name: "francia", iso_code:'fr' }},
                    ],
                },
                {
                    id: 8,
                    comodity: ['consumer'],
                    preregulatory: false,
                    title: 'Francia - Informazioni ',
                    text: 'Francia - Informazioni ',
                    date: "2022-08-15T00:00:00+01:00",
                    countries: [
                        { id: 1, label: "Italia", value: { name: "italia", iso_code:'it' }},
                        { id: 3, label: "Francia", value: { name: "francia", iso_code:'fr' }},
                    ],
                },
                {
                    id: 9,
                    comodity: ['food'],
                    preregulatory: false,
                    title: 'Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano 3',
                    text: 'Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano 3',
                    date: "2022-08-15T00:00:00+01:00",
                    countries: [
                        { id: 1, label: "Italia", value: { name: "italia", iso_code:'it' }},
                        { id: 2, label: "Germania", value: { name: "germania", iso_code:'de' }},
                        { id: 10, label: "Svizzera", value: { name: "svizzera", iso_code:'ch' }},
                        { id: 3, label: "Francia", value: { name: "francia", iso_code:'fr' }},
                    ],
                },
                {
                    id: 10,
                    comodity: ['food'],
                    preregulatory: true,
                    title: 'Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano',
                    text: 'Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano',
                    date: "2022-08-15T00:00:00+01:00",
                    countries: [
                        { id: 1, label: "Italia", value: { name: "italia", iso_code:'it' }},
                        { id: 2, label: "Germania", value: { name: "germania", iso_code:'de' }},
                        { id: 3, label: "Francia", value: { name: "francia", iso_code:'fr' }},
                    ],
                },
                {
                    id: 11,
                    comodity: ['food'],
                    preregulatory: false,
                    title: 'Francia - Informazioni ',
                    text: 'Francia - Informazioni ',
                    date: "2022-08-15T00:00:00+01:00",
                    countries: [
                        { id: 1, label: "Italia", value: { name: "italia", iso_code:'it' }},
                        { id: 2, label: "Germania", value: { name: "germania", iso_code:'de' }},
                        { id: 10, label: "Svizzera", value: { name: "svizzera", iso_code:'ch' }},
                        { id: 3, label: "Francia", value: { name: "francia", iso_code:'fr' }},
                    ],
                },
                {
                    id: 12,
                    comodity: ['consumer'],
                    preregulatory: false,
                    title: 'Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano 3',
                    text: 'Francia - Informazioni al consumatore sulle qualità e caratteristiche ambientali di prodotti che generano 3',
                    date: "2022-08-15T00:00:00+01:00",
                    countries: [
                        { id: 1, label: "Italia", value: { name: "italia", iso_code:'it' }},
                        { id: 10, label: "Svizzera", value: { name: "svizzera", iso_code:'ch' }},
                        { id: 3, label: "Francia", value: { name: "francia", iso_code:'fr' }},
                    ],
                },
            ],
        };
    },
    actions,
    mutations,
    getters,
};
