/* import * as types from "./mutation-types"; */

export default {
  addItem(state, new_item){
    if(new_item){
        state.searchs.push(new_item)
        return
    }
    console.log("ERRORE MUTATION NOTIFICATION -> addItem");
    return
  },
  replaceItem(state, {id, new_value}){

    if(id && new_value){
      let index = state.searchs.findIndex(el => el.id == id)
          state.searchs[index] = new_value
          return
      }
      console.log("ERRORE MUTATION NOTIFICATION -> replaceItem");
      return
  },
  deleteItem(state, item){
      if(item){
        state.searchs = state.searchs.filter(el => el.id != item.id)
        return
      }
      console.log("ERRORE MUTATION NOTIFICATION -> replaceItem");
      return
  },

  //
  setSentSearch(state, search){
    state.sent_search = search
  },
  resetSentSearch(state){
    state.sent_search = null
  },
};
