<template>
   <div class="page-home--stats py-section">
      <div class="blob-2"><img src="@/assets/img/texture/blob-2.png" alt=""></div>
      <div class="blob-4"><img src="@/assets/img/texture/blob-1.png" alt=""></div>

      <div class="container">

         

         <!-- Sezione Grafici -->
         <div class="grid grid-cols-12">
            <div class="col-span-12 lg:col-span-4">
               <h3 class="font-bold text-primary mb-2">{{ $t("home.graph.title") }}</h3>
               <v-list class="v-list__stats">
                  <v-list-item-group v-model="selected_item" color="primary">
                     <v-list-item v-for="(item, i) in graphs_label" :key="i">
                        <!-- <v-list-item-icon>
                           <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon> -->
                        <v-list-item-content>
                           <v-list-item-title
                              v-text="item.text"
                           ></v-list-item-title>
                        </v-list-item-content>
                     </v-list-item>
                  </v-list-item-group>
               </v-list>
            </div>
            <div class="col-span-12 lg:col-span-8">
              <div v-switch="selected_item" class="sm:ml-10 w-max-100">
                <div v-case="0">
                   <histogram-country :chartData="histogram_country_data" :height="50" :width="100" ></histogram-country>

                </div>
                <div v-case="1">
                    <line-tag :chartData="line_tag_data"></line-tag>
                </div>
                <div v-case="2">
                    <h2>2</h2>
                </div>
                <div v-case="3">
                    <h2>3</h2>
                </div>
            </div>
            </div>
         </div>

      </div>

      <!-- Ultimi Update - CAROSELLO -->
      <div class="my-section" data-aos="fade-up">
         <div class="container">
            <h2 class="title--section mb-5" >
               {{ $t("home.update.title") }}
            </h2>

            <carousel
               class="carousel carousel--top-news"
               :loop="false"
               :rewind="true"
               :dots="true"
               :nav="false"
               :margin="18"
               :autoplay="true"
               :autoplayHoverPause="true"
               :center="true"
               :stagePadding="0"
               :startPosition="1"
               :responsive="{
                  0:{
                     items: 1,
                  },
                  840:{
                     items: 2,
                  }
               }"
            >  
            <div v-for="update in updates" :key="update.id">
               <card-update
                  class="mb-1"
                  
                  :update="update"
               ></card-update>
            </div>
            </carousel>

         </div>
      </div>

      <!-- Preregulatory - TABELLA -->
      <div class="my-section" data-aos="fade-up">
         <div class="container">
            <h3 class="title--underline color-primary mb-5" >
               {{ $t("home.entry-force.title") }}
            </h3>

            <table-update
               class="mb-1"
               :updates="updates_pass"
            ></table-update>
            
         </div>
      </div>

      <div class="container">
         <h3 class="title--underline color-primary mb-5" >
            {{ $t("home") }} <!-- Gli ultimi aggiornamenti furoi dal tuo abbonamento -->
         </h3>
         <div class="grid grid-cols-2 gap-10 mt-3 ">
            <div
               v-for="update in updates_out_subscription"
               :key="update.id"
               class="col-span-1 card-update--container__grid-mode"
            >
               <card-update :update="update" :checkbox_abilitate="false"></card-update>
            </div>
         </div>
         
      </div>
      
   </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import TableUpdate from "@/components/TableUpdate.vue";

import HistogramCountry from "./../graphs/HistogramCountry.vue"
import LineTag from "./../graphs/LineTag.vue"

export default {
   components: {
      carousel,
      TableUpdate,
      HistogramCountry,
      LineTag
   },
   data() {
      return {
        selected_item: 0,
         graphs_label: [
            { text: "Update per paese" },
            { text: "Andamento semestrale" },
         ],
         line_tag_data: {
            labels: [
               'Marzo - 2022',
               'Aprile - 2022',
               'Maggio - 2022',
               'Giugno - 2022',
               'Luglio - 2022',
               'Agosto - 2022'
            ],
            datasets: [
               {
                  label: 'Labeling',
                  backgroundColor: '#F6C644',
                  borderColor: '#F6C644',
                  data: [10, 15, 5, 12, 2, 12]
               },
               {
                  label: 'Organoleptic',
                  backgroundColor: '#0B2551',
                  borderColor: '#0B2551',
                  data: [ 15, 5, 10, 20, 12, 23]
               },
               {
                  label: 'Aspects',
                  backgroundColor: '#CB2B1E',
                  borderColor: '#CB2B1E',
                  data: [5, 12, 7, 8, 20, 8]
               },
            ]
         },
         histogram_country_data: {
            labels: [ 'Italia', 'Germania', 'Francia', 'Spagna', 'Norvegia', 'Svezia', 'Polonia', 'Estonia', 'Brasile', 'Canada'],
            // datasets: [ { data: [15, 1, 8, 7, 9, 1, 25, 9, 7, 11] } ],

            datasets: [
               {
                  label: 'Updates',
                  backgroundColor: '#133C6C',
                  data: [ 7, 9, 1, 25, 11, 7, 11, 18, 1, 8]
               },
               {
                  label: 'Preregulatory',
                  backgroundColor: '#B99666',
                  data: [15, 1, 8, 7, 9, 1, 12, 9, 7, 11]
               },
            ]
         },
      };
   },
   computed: {
      updates() {
         return this.$store.getters['updates/updates']
      },
      updates_out_subscription() {
         return this.$store.getters['updates/updatesNumber'](6)
      },
      updates_pass() {
         return this.$store.getters['updates/updatesNumber'](8)
      },
      dimension(){
      let ref = document.getElementById("containerRef");
      console.log('red',ref);
         let t = (window.innerWidth - ref.clientWidth) / 2
         console.log("qui",t);
         return t
      }
   },
};
</script>

<style lang="scss" scoped>
/*  */
</style>