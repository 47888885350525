<template>
    <v-dialog
        :persistent="true"
        v-model="dialog"
        width="40vw"
        content-class=""
    >
      <template v-slot:activator="{ on, attrs }">
          <a
            v-bind="attrs"
            class="modal-update--control modal-update--control__consulenza"
            v-on="on"
          >
            <v-icon>mdi-account</v-icon>
            Chiedi una consulenza
          </a>
      </template>

      <div class="modal-update relative flex flex-column gap-[30px] p-[3.5rem]">

        <a
          @click="dialog = false"
          class="modal-close-icon"
        >
          <v-icon>mdi-close-circle-outline</v-icon>
        </a>

        <div>
          <h3 class="font-bold color-primary">Contattaci</h3>
          
          <div class="border-list mt-7">
            <div class="border-list--item">
              <v-icon>mdi-home</v-icon>
              <span>+39 3401170616</span>
            </div>
            <div class="border-list--item">
              <v-icon>mdi-home</v-icon>
              <span>+39 3401170616</span>
            </div>
          </div>
        </div>

        <div>
          <h3 class="font-bold color-primary mb-2">Contattaci</h3>

          <div class="form-group">
            <v-text-field
              class="form-group--input"
              label="Email"
              v-model="form_data.email"
              outlined
              dense
              :rules="email_rules"
              hide-details="auto"
            ></v-text-field>
          </div>
          <div class="form-group">
            <v-text-field
              class="form-group--input"
              label="Numero di telefono"
              v-model="form_data.phone"
              outlined
              dense
              :rules="phone_rules"
              hide-details="auto"
            ></v-text-field>
          </div>
          <div class="form-group">
            <v-textarea
              class="form-group--input"
              label="Numero di telefono"
              v-model="form_data.text"
              outlined
              dense
              :rules="text_rules"
              hide-details="auto"
            ></v-textarea>
          </div>

          <div class="flex justify-center mt-8">
            <v-btn
              class="w-[400px]"
              color="primary"
            > 
              INVIA
            </v-btn>
          </div>
        </div>

      </div>
    </v-dialog>
</template>

<script>
  export default {
    props: ["type","update"],
    data () {
      return {
        dialog: false,
        form_data: {
          email: '',
          phone: '',
          text: ''
        },
        text_rules: [
          value => !!value || 'Campo Richiesto.',
        ],
        phone_rules: [
          value => value.length > 0 || 'Campo Richiesto.',
          value => Number.isInteger(value) || 'Il numero deve essere valido',
        ],
        email_rules: [
          value => !!value || 'Campo Richiesto.',
          value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'L\'e-mail deve essere valida'
        ],
      }
    },
  }
</script>

<style lang="scss">
  .trigger-link{

    &--card{
      display: -webkit-box;
  
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
  
      font-weight: bold;
      color: $primary;
    }
    &--table:not(.not){
      display: block;
      width: 750px;

      color: $black;

      &:hover{
        color: $primary;
      }

    }
  }

  .modal-update{
    background: white;
    min-height: 100%;
    max-height: 100%;
    overflow-y: scroll;

    &--container{
      padding: 5rem;
      padding-left: 5rem;
      height: fit-content;
    }

    &--categorie{
      width: fit-content;
      padding: .5rem 4rem .5rem .5rem;
      clip-path: polygon(100% 0%, calc(100% - 24px) 50%, 100% 100%, 0% 100%, 0% 0%);
      max-width: 300px;
      display: flex;
      flex-wrap: nowrap;
      
      &--container{
        position: sticky;
        top: 0;
        margin-left: 0;
        padding-top: 50px;
      }
    }

    &--control{
      border-bottom: 1px solid;
      padding-bottom: .2rem;
      padding-left: 1rem;
      transition: transform .3s ease;

      &:not(:first-child){
        margin-top: 1rem;
      }

      &:hover{
        transform: translateY(-2px)
      }

      &__preferiti:not(.not){
        color: #CFA143;
        border-color: #CFA143;
        & > .v-icon{
          color: #CFA143;
        }
      }
      &__consulenza:not(.not){
        color: #0F2F58;
        border-color: #0F2F58;
        & > .v-icon{
          color: #0F2F58;
        }
      }
      &__catalogo:not(.not){
        color: #97C571;
        border-color: #97C571;
        & > .v-icon{
          color: #97C571;
        }
      }
      &__notifica:not(.not){
        position: relative;
        color: #E3D02F;
        border-color: #E3D02F;
        & > .v-icon{
          color: #E3D02F;
        }

        &.disabled{
          pointer-events: none;
          color: grey;
          border-color: grey;
          & > .v-icon{
            color: grey;
          }
        }

        .modal-update--control__notifica__forecast{
          color: $black;
          position: absolute;
          white-space: nowrap;
          bottom: 0;
          right: 0;
          transform: translateY(120%);
        }
      }
    }
  }
  
</style>