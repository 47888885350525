<template>
    <div class="card-collection p-[2.5rem] flex justify-between gap-[2.5rem]">
        <div class="basis-1/3 flex flex-col gap-4">
            <div>
                <div v-if="collection.type == 'dynamic'" class="text-grey font-semibold" >
                    <span v-if="collection.periodicity == 'day'">collectiono giornaliero</span>
                    <span v-if="collection.periodicity == 'week'">collectiono settimanale</span>
                    <span v-if="collection.periodicity == 'month'">collectiono mensile</span>
                </div>
                <div v-else>
                    <span v-if="collection.dateStart" class="text-grey font-semibold">{{formatDate(collection.dateStart)}} <span v-if="collection.dateEnd"> - {{formatDate(collection.dateEnd)}}</span></span>
                </div>
            </div>
            <img
                v-if="collection.thumb"
                class="w-[100%] h-[150px] object-cover"
                :src="collection.thumb"
            >
            <div>
                <v-btn
                    class="w-[100%]"
                    color="primary"
                    elevation="1"
                    outlined
                    :to="'collections/' + collection.id"
                >
                    Visualizza update
                </v-btn>
            </div>
        </div>
        <div class="basis-2/3 flex flex-col gap-[1.5rem]">
            <div class="card-collection--header flex justify-between">
                <span class="font-bold text-primary">{{collection.title}}</span>
                <router-link
                    :to="'collections/edit/' + collection.id"
                    class="icon-hover"
                >
                    <v-icon >mdi-cog</v-icon>
                </router-link>
            </div>
            <div class="grow">
                <p>{{collection.description}}</p>
            </div>
            <div class="flex justify-end">
                <div class="text-right w-[200px] font-bold text-primary">
                    <p>Download</p>
                    <hr style="border-color: #0A4479" class="mb-1">
                    <div class="flex justify-end gap-5">
                        <a class="icon-hover">XLS: <v-icon>mdi-download</v-icon></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['collection'],
}
</script>

<style lang="scss" scoped>
/*  */
.card-collection{
    box-shadow: 2px 2px 8px rgba($black, .3);

    &:not(:last-child){
        margin-bottom: 5rem;
    }

    &--header{
        border-bottom: 1px solid $primary;
    }
}
</style>