<template>
<div id="hamburgerMenu" class="hamburger-menu">
   
  <v-speed-dial
      v-model="fab"
      :bottom="true"
      :right="true"
      direction="left"
      transition="slide-x-reverse-transition"
   >
      <template v-slot:activator>
         <v-btn
            v-model="fab"
            color="quaternary"
            dark
            fab
         >
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-menu </v-icon>
         </v-btn>
      </template>

      <div v-for="menu in menus" :key="menu.name">

         <v-tooltip :color="menu.color" top>
            <template v-slot:activator="{ on, attrs }">

               <v-btn
                  v-bind="attrs"
                  v-on="on"
                  fab
                  dark
                  small
                  :color="menu.color"
                  @click="triggerRouter(menu.link)"
               >
                  <v-icon>{{ menu.icon }}</v-icon>
               </v-btn>

            </template>
            <span>{{ menu.name }}</span>
         </v-tooltip>
      </div>
   </v-speed-dial>
</div>
   
</template>

<script>
export default {
   data() {
      return {
         fab: false,
         menus: [
            { name: "Home", color: "#255678", icon: "mdi-home", link: "" },
            { name: "Aggiornamenti", color: "#256288", icon: "mdi-newspaper-variant-multiple", link: "updates" },
            { name: "Journal", color: "#25709A", icon: "mdi-bell", link: "journal" },
            { name: "Collection", color: "#247EAC", icon: "mdi-star", link: "collections" },
            { name: "Ricerca avanzata", color: "#248BBE", icon: "mdi-text-search", link: "search" },
            { name: "Account", color: "#2398CF", icon: "mdi-account", link: "account" },
         ],
         
      };
   },
   methods: {
      triggerRouter(link){
         this.$router.replace('/'+link);
      }
   },
};
</script>

<style lang="scss" scoped>
/*  */
#hamburgerMenu{
   margin-top: -56px;
   transition: opacity .3s ease;
   &:not(.show){
      opacity: 0;
   }

   .v-btn:hover{
      color: white;
   }
}
</style>