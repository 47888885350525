<template>
    <div class="card-news">
        <h5 class="card-news--title">{{news.title}}</h5>
        <div class="card-news--date">
            <v-icon>mdi-calendar</v-icon>
            {{news.date}}
        </div>
        <div class="card-news--tags" >
            <div
                v-for="tag in news.tags"
                :key="tag"
                class="card-news--tag"
            >
                {{tag}}
            </div>
        </div>
        <p class="card-news--text">
            {{news.text}}
        </p>
    </div>
</template>

<script>

export default ({
    props: ['news'],

})
</script>

<style lang="scss" scoped>
    .card-news{
        &--title{
            font-size: 16px;
            font-weight: 700;
            color: #333;
            margin-bottom: 1.5rem;
        }
        &--date{
            font-size: 14px;
            color: #777;
        }
        &--tags{
            display: flex;
            gap: 5px;
        }
        &--tag{
            padding: 0 .5rem;
            font-size: 14px;
            background: #5bc0de;
            color: white;
        }
        &--text{
            font-size: 14px;
            color: #777;
        }

        & > *:not(:last-child){
            margin-bottom: 1rem;
        }
    }
</style>
