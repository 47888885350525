<template>
    <div class="card-update shadow" :class="{'card-update__preregulatory': update.preregulatory}">
        <div class="card-update--blobs">
            <img v-if="!update.preregulatory" class="card-update--blobs--item max-w-[75px]" src="@/assets/img/texture/blob-2.png" alt="">
            <img v-else class="card-update--blobs--item max-w-[75px]" src="@/assets/img/texture/blob-brown-2.png" alt="">
            <img v-if="!update.preregulatory" class="card-update--blobs--item max-w-[75px]" src="@/assets/img/texture/blob-1.png" alt="">
            <img v-else class="card-update--blobs--item max-w-[75px]" src="@/assets/img/texture/blob-brown-1.png" alt="">
        </div>
        <img v-if="update.preregulatory" class="card-update--preregulatory-mark" src="@/assets/img/texture/preregulatory-mark.png" alt="">
        <div class="card-update--header">
            <h5 class="card-update--title">
                <modal-update :type="'card'" :update="update"></modal-update>
            </h5>
            <div v-if="update.preregulatory" class="card-update--preregulatory-mark_placeholder"></div>
            
        </div>
        <div class="card-update--body">
            <ul class="pl-0">
                <li>
                    <span><v-icon class="mr-1">mdi-calendar</v-icon></span>
                    <span>26 maggio 2022</span>
                </li>
                <li>
                    <span><v-icon class="mr-1">mdi-tag</v-icon></span>
                    <span>tutte le categorie</span>
                </li>
                <li>
                    <span><v-icon class="mr-1">mdi-star</v-icon></span>
                    <span>presente nella wish</span>
                </li>
                <li>
                    <span><v-icon class="mr-1">mdi-information-outline</v-icon></span>
                    <span>Source: Lorem, ipsum</span>
                </li>
            </ul>
            <div class="flex flex-col align-end">
                <div class="mb-1" v-if="update.comodity.includes('food')">
                    <span>Regulatory Update Online | Food</span>
                    <span><v-icon class="ml-1">mdi-food-apple</v-icon></span>
                </div>
                <div v-if="update.comodity.includes('consumer')">
                    <span>Regulatory Update Online | Consumer</span>
                    <span><v-icon class="ml-1">mdi-liquor</v-icon></span>
                </div>
            </div>
        </div>
        <div class="card-update--footer">
            <div class="flex gap-2">
                <span > <!-- v-for="category in update.category" :key="category" -->
                    <v-tooltip :open-delay="tooltip_open_delay" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                class="tag-icon bg-red color-white"
                                v-bind="attrs"
                                v-on="on"
                                small
                            >mdi-pencil</v-icon>
                        </template>
                        <span>pencil</span>
                    </v-tooltip>
                </span>

                <!-- CANCELLA -->
                <span > 
                    <v-tooltip :open-delay="tooltip_open_delay" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                class="tag-icon bg-green color-white"
                                v-bind="attrs"
                                v-on="on"
                                small
                            >mdi-barley</v-icon>
                        </template>
                        <span>barley</span>
                    </v-tooltip>
                </span>
                <span > 
                    <v-tooltip :open-delay="tooltip_open_delay" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                class="tag-icon bg-blue-500 color-white"
                                v-bind="attrs"
                                v-on="on"
                                small
                            >mdi-home</v-icon>
                        </template>
                        <span>home</span>
                    </v-tooltip>
                </span>
                <!-- CANCELLA -->
                

            </div>
            <div v-if="update.countries && update.countries.length > 0" class="card-update--flag flex gap-3">
                <span v-for="flag in update.countries" :key="flag.name">
                    <v-tooltip :open-delay="tooltip_open_delay" top>
                        <template v-slot:activator="{ on, attrs }">
                            <a v-bind="attrs" v-on="on" @click="openCountry(flag.id)">
                                <country-flag :country='flag.value.iso_code' />
                            </a>
                        </template>
                        <span class="capitalize">{{ flag.label }}</span>
                    </v-tooltip>
                </span>
            </div>
        </div>

        <div class="card-update--checkbox" :class="{'abilitate': checkbox_abilitate}">
            <div>
                <v-checkbox
                    v-model="checkbox"
                    hide-details
                ></v-checkbox>
            </div>
        </div>
    </div>    
</template>

<script>
import ModalUpdate from "@/components/modals/ModalUpdate.vue"
export default {
    props: ['update','checkbox_abilitate','reset'],
    components: {
        ModalUpdate,
    },
    data() {
        return {
            checkbox: false,
            dialog_trash: false
        }
    },
    watch: {
        reset(){
            if(this.reset){
                this.checkbox = false
            }
        },
        checkbox() {
            let event = {
                checked: this.checkbox,
                id: this.update.id
            }
            this.$emit('checkboxChange',event)
        },
    },
    methods: {
        /* async eliminateFromCatalog() {
            let params = {
                update_id: this.update.id,
                catalog_id: this.catalog_membership.id,
            }
            this.mainShowLoading()
            await this.$store.dispatch('catalogs/eliminateUpdate',params)
            this.mainHideLoading()
        }, */
        openCountry(id){
            let this_path = this.$router.currentRoute.path
            let next_path = '/countries/' + id + '/food'
            if(this_path != next_path){
                this.$router.replace(next_path);
            }
        }
    },
}
</script>

<style lang="scss" scoped>
/*  */
</style>