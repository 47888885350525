<template>

   <div class="intro-account">
      <div class="blob-2"><img src="@/assets/img/texture/blob-2.png" alt="" /></div>
      <div class="blob-4"><img src="@/assets/img/texture/blob-1.png" alt="" /></div>

    <div class="text-center">
        <div class="flex flex-col align-center mb-2">
            <h1 class="font-bold like-h2 color-primary w-[fit-content] p-[.3rem_5rem]">
                Benvenuto <br>
                {{user.name}} {{user.last}} 
            </h1>
        </div>
        <div class="intro-decoration mt-2 mb-4">
            <img src="@/assets/img/texture/intro-decoration.png">
        </div>
    </div>
    <div class="container">
        <div class="shadow-pannel p-[5rem_3rem]">
            <v-row class="info-account">
                <v-col cols="4">
                    <p>Tipo account:</p>
                    <h4 class="uppercase"><b class="color-primary">{{user.type_account}}</b></h4>
                </v-col>
                <v-col cols="4">
                    <p>Tipo account:</p>
                    <h4 class="uppercase"><b class="color-primary">{{user.agency}}</b></h4>
                </v-col>
                <v-col cols="4">
                    <p>Tipo account:</p>
                    <h4 class="uppercase"><b class="color-primary">{{user.allert_max}}</b></h4>
                </v-col>
            </v-row>
        </div>
    </div>
    


   </div>
</template>

<script>
export default {
    props: ['user'],
};
</script>

<style lang="scss" scoped>
/*  */
.intro-account{

    
    @include box-shadow();
    
    margin-top: -130px;
    padding: 8.5rem 0 0rem 0;
    position: relative;
    min-height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .info-account > *{
        padding-right: 3rem;
        padding-left: 3rem;
        &:not(:last-child){
            border-right: 1px solid #F7F7F7;
        }
    }
}

.intro-decoration{
   display: flex;
   justify-content: center;
   position: relative;

   img{
      position: relative;
      z-index: 2;
      padding: 0 1.5rem;
      background: white;
   }

   &::after{
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      height: 3px;

      background: linear-gradient(90deg, rgba($primary, 0) 2%, $primary 35%, $primary 75%, rgba($primary, 0) 98%);
   }
}
</style>