<template>
    <div class="container">
        <div class="flex justify-between align-end list-updates--header">
            <div v-if="passed_title">
                <h3 class="color-primary font-bold">{{passed_title}}</h3>
            </div>
            <div v-else>
                <span>{{updates.length}} elementi </span>
                <!-- {{update_checked}} --> <!-- CANCELLARE -->
            </div>
            <div class="flex gap-2 list-updates--controls">
                <!-- Push Out Collection -->
                <v-tooltip
                    v-if="checkbox_abilitate && collection"
                    :open-delay="tooltip_open_delay"
                    top
                >
                    <template v-slot:activator="{ on, attrs }">
                        <div class="inline-block">
                            <!-- {{update_checked}} -->
                            <v-btn
                                v-if="update_checked.length == 0"
                                v-bind="attrs"
                                v-on="on"
                                class="disabled"
                                color="white"
                                elevation="3"
                                icon
                            ><v-icon>mdi-archive-arrow-up-outline</v-icon></v-btn>
                            <v-btn
                                v-else
                                v-bind="attrs"
                                v-on="on"
                                class="bg-red"
                                color="white"
                                elevation="3"
                                @click="EliminateToCollection"
                                icon
                            ><v-icon>mdi-archive-arrow-up-outline</v-icon></v-btn>
                        </div>
                    </template>
                    <div class="max-w-[200px]">
                        <p>Elimina dalla collezione</p>
                    </div>
                </v-tooltip>

                <v-tooltip
                    v-if="checkbox_abilitate"
                    :open-delay="tooltip_open_delay"
                    top
                >
                    <template v-slot:activator="{ on, attrs }">
                        <div class="inline-block pr-[1.3rem] mr-[calc(1.3rem-0.5rem)]" style="border-right: 1px solid black">
                            <!-- {{update_checked}} -->
                            <v-btn
                                v-if="update_checked.length == 0"
                                v-bind="attrs"
                                v-on="on"
                                class="disabled"
                                color="white"
                                elevation="3"
                                icon
                            ><v-icon>mdi-download</v-icon></v-btn>
                            <v-btn
                                v-else
                                v-bind="attrs"
                                v-on="on"
                                class="bg-green"
                                color="white"
                                elevation="3"
                                @click="downloadUpdates"
                                icon
                            ><v-icon>mdi-download</v-icon></v-btn>
                        </div>
                    </template>
                    <div class="max-w-[200px]">
                        <p>Download</p>
                    </div>
                </v-tooltip>

                <v-tooltip :open-delay="tooltip_open_delay" top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="bg-primary"
                            color="white"
                            elevation="3"
                            @click="checkbox_abilitate = !checkbox_abilitate"
                            icon
                        ><v-icon>mdi-checkbox-marked-outline</v-icon></v-btn>
                    </template>
                    <div class="max-w-[200px]">
                        <p>Abilita selezione multipla</p>
                    </div>
                </v-tooltip>
                <v-tooltip v-if="!disable_change_mode" :open-delay="tooltip_open_delay" top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-if="grid_mode"
                            v-bind="attrs"
                            v-on="on"
                            class="bg-primary"
                            color="white"
                            elevation="3"
                            @click="grid_mode = !grid_mode"
                            icon
                        ><v-icon>mdi-format-list-bulleted</v-icon></v-btn>
                        <v-btn
                            v-else
                            v-bind="attrs"
                            v-on="on"
                            class="bg-primary"
                            color="white"
                            elevation="3"
                            @click="grid_mode = !grid_mode"
                            icon
                        ><v-icon>mdi-grid</v-icon></v-btn>
                    </template>
                    <div class="max-w-[200px]">
                        <p>Cambio visualizazione</p>
                    </div>
                </v-tooltip>
                
            </div>
        </div>
        <div v-if="updates.length">

            <div class="grid grid-cols-2 gap-10 mt-3 ">
                <div
                    v-for="update in updates"
                    :key="update.id"
                    class="col-span-2 card-update--container "
                    :class="{'col-span-1 card-update--container__grid-mode': grid_mode}"
                >
                    <card-update @checkboxChange="checkboxChange" :update="update" :checkbox_abilitate="checkbox_abilitate" :reset="reset"></card-update>
                </div>
            </div>
        </div>
        <div v-else class="py-section">
            non ci sono update
        </div>
    </div>
</template>

<script>

export default {
    props: [
        'passed_update',
        "passed_title",
        "passed_grid_mode",
        'disable_change_mode',
        "catalog_membership",
        'reset',

        'collection',
    ],
    data() {
        return {
            grid_mode: false,
            checkbox_abilitate: false,
            update_checked: [],
        }
    },
    computed: {
        updates() {
            if(this.passed_update){
                return this.passed_update
            }
            return this.$store.getters['updates/updates']
        },
    },
    watch: {
        reset(){
            if(this.reset){
                this.grid_mode = false
                this.checkbox_abilitate = false
                this.update_checked = []
            }
        },
    },
    methods: {
        checkboxChange(event) {
            if (event.checked) {
                this.update_checked.push(event.id)
            } else {
                this.update_checked = this.update_checked.filter( id => id != event.id )
            }
        },
        downloadUpdates(){
            this.update_checked.sort()
            console.log("scarica i seguenti updates", this.update_checked);
        },
        EliminateToCollection(){
            
            let params = {
                id_collection: this.collection.id,
                updates: this.update_checked,
            }

            this.setLoading(true)
            this.$store.dispatch("collections/removeUpdates",params)

            this.update_checked = []

            console.log("elimina", this.update_checked);
            console.log("da", this.collection);
        }
    },
    created () {
        if(this.passed_grid_mode){
            this.grid_mode = this.passed_grid_mode
        }
    },
}
</script>

<style lang="scss" scoped>
    .list-updates{
        &--header{
            padding-bottom: 1rem;
            border-bottom: 1px solid $border-color;
        }
    }
</style>