<template>
    <div id="loadingFrame" class="loading-frame" :class="{'hide': !loadingPage}">
        <v-progress-circular
            :size="65"
            color="white"
            indeterminate
        ></v-progress-circular>

        <!-- CANCELLA -->
        <v-btn style="
            position: fixed;
            top: 10px;
            right: 10px;
        " @click="hideLoading()">Nascondi</v-btn>
    </div>
</template>

<script>
export default {
    computed: {
        loadingPage() {
            return this.$store.getters.loadingPage
        }
    },
    methods: {
        hideLoading() {
            this.mainHideLoading()
        }
    },
}
</script>

<style lang="scss" scoped>
    .loading-frame{
        background: rgba(royalblue, .8);
        backdrop-filter: blur( 11px );
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 250000;
        display: flex;
        justify-content: center;
        align-items: center;

        transition: opacity .3s ease;
        opacity: 1;

        &.hide{
            pointer-events: none;
            opacity: 0;
        }
    }
</style>