class BaseApiService {
    //baseUrl = "BASEURL/";
    baseUrl = "BASEURL";
    token = 'TOKEN';
    route_map = {};
    config =  {
        Authorization: `Bearer ${this.token}`
    };

    constructor(route_map) {
      if (!route_map) throw new Error("Route map is not provided");
      this.route_map = route_map;
    }
  
    getUrl(route_name, id = "") {
        if( !this.route_map[route_name] ) throw new Error(`Route ${route_name} not fount`);
        let url_part = this.route_map[route_name];
        console.log(url_part);
        if( id != "" )
            url_part.replace('{id}', id);

        return `${this.baseUrl}/${url_part}`;
    }
  
    async server_fetch(route_name, id = "", config = {}) {
        try {
            config = Object.assign(this.config, config);
            const response = await fetch(this.getUrl(route_name, id), config);
            return await response.json();
        } catch (err) {
            this.handleErrors(err);
        }
    }

    handleErrors(err) {
        // Note: here you may want to add your errors handling
        console.log({ message: "Errors is handled here", err });
    }
}

class CastingApiService extends BaseApiService {
    
    constructor() {
        let route_map = {
            "get": "casting/{id}",
            "getAll": "casting",
            "post_update": "casting/{id}/edit"
        } 

        super(route_map);
    }

    async getCasting(id){
        return await this.server_fetch('get',id);
    }

    async getCastings(){
        return await this.server_fetch('getAll');
    }

    async updateCasting(id, data){
        let config = {
            method: "PUT",
            body: JSON.stringify(data)
        }
        return await this.server_fetch('post_update',id, config);
    }

}


export const $api = {
    casting: new CastingApiService(),
};