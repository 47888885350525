let timer;

export default {
    async login(context, payload) {
        return context.dispatch('auth', payload);
    },
    //   async signup(context, payload) {
    //     return context.dispatch('auth', {
    //       ...payload,
    //       mode: 'signup'
    //     });
    //   },
    async auth(context, payload) {

        // let url = '';
        let response = null;
        let responseData = null;

        await new Promise(resolve => setTimeout(resolve, 1500));
        if( payload.email == 'test@app.com' && payload.password == 'password' ){
            response = { ok: true, data: {} };
            responseData = {
                profileid: 3,
                name: "Mario",
                surname: "Rossi",
                role: "admin",
                token: "s1351a1fa3sdf43sf1a3sd13f1a31fas3df11e34we",
                token_duration: 36000
            }
        } else {
            response = { ok: false, data: {} };
            responseData = {
                message: "Failed to authenticate"
            };
        }
        // const response = await fetch(url, {
        //   method: 'POST',
        //   body: JSON.stringify({
        //     email: payload.email,
        //     password: payload.password,
        //     returnSecureToken: true
        //   })
        // });

        // const responseData = await response.json();
         


        if (!response.ok) {
            const error = new Error(
                responseData.message || 'Failed to authenticate. Check your login data.'
            );
            throw error;
        }

        const expiresIn = +responseData.token_duration * 1000;
        // const expiresIn = 5000;
        const expirationDate = new Date().getTime() + expiresIn;

        // localStorage.setItem('token', responseData.token);
        localStorage.setItem('profile', JSON.stringify(responseData));
        localStorage.setItem('tokenExpiration', expirationDate);

        timer = setTimeout(function () {
            context.dispatch('autoLogout');
        }, expiresIn);

        context.commit('setAuthprofile', responseData );
    },
    async tryLogin(context) {
        const profile = await JSON.parse(localStorage.getItem('profile'));
        const token =  profile?.token ?? null;
        const tokenExpiration = await localStorage.getItem('tokenExpiration');

        const expiresIn = +tokenExpiration - new Date().getTime();

        if (expiresIn < 0) {
            throw { message: 'expired token' };
        }

        timer = setTimeout(function () {
            context.dispatch('autoLogout');
        }, expiresIn);

        if (token && profile) {
            console.log('auto logging in the profile');
            context.commit('setAuthprofile', profile );
        } else {
            throw { message: 'invalid token' };
        }
    },
    logout(context) {

        localStorage.removeItem('profile');
        localStorage.removeItem('tokenExpiration');

        clearTimeout(timer);

        context.commit('setAuthprofile', null );
    },
    autoLogout(context) {
        context.dispatch('logout');
        context.commit('setAutoLogout');
    },
    async editUser(context, new_value){

        await new Promise(resolve => setTimeout(resolve, 2000)); //CAMBIO LOGICA CHIAMATA

        let rensonse = true //CAMBIO LOGICA CHIAMATA
        if(rensonse){ //CAMBIO LOGICA CHIAMATA
            context.commit('replaceItem',new_value)
            return new_value
        } //CAMBIO LOGICA CHIAMATA

        return 'error'
    }
};
