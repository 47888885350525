/* import * as types from "./mutation-types"; */
import moment from "moment";

export default {
    getSentSearch(context, search){
        context.commit('setSentSearch',search)
    },
    resetSentSearch(context){
        context.commit('resetSentSearch')
    },
    async saveSearch(context, form){
        
        let last =  context.state.searchs.at(-1)
        let id
        if(last){
            id = last.id
        } else{
            id = 0
        }

        let title = "title search " + id + "|" + moment()
        let new_value = {
            id: id+1,
            title: title,
            form: form,
        }

        await new Promise(resolve => setTimeout(resolve, 2000)); //CAMBIO LOGICA CHIAMATA

        let rensonse = true //CAMBIO LOGICA CHIAMATA
        if(rensonse){ //CAMBIO LOGICA CHIAMATA
            console.log("new_value",new_value);
            context.commit('addItem',new_value)
            return new_value
        } //CAMBIO LOGICA CHIAMATA

        return 'error'
    },
    async editSearch(context, new_value){

        await new Promise(resolve => setTimeout(resolve, 2000)); //CAMBIO LOGICA CHIAMATA

        let rensonse = true //CAMBIO LOGICA CHIAMATA
        if(rensonse){ //CAMBIO LOGICA CHIAMATA
            context.commit('replaceItem',{id: new_value.id,new_value:new_value})
            return new_value
        } //CAMBIO LOGICA CHIAMATA

        return 'error'
    },
    async deleteSearch(context, item){
        await new Promise(resolve => setTimeout(resolve, 2000)); //CAMBIO LOGICA CHIAMATA

        let rensonse = true //CAMBIO LOGICA CHIAMATA
        if(rensonse){ //CAMBIO LOGICA CHIAMATA
            context.commit('deleteItem',item)
        } //CAMBIO LOGICA CHIAMATA

        return 'error'
    },
};
