export default {
    journals(state){
        let t = state.journals
        if(t){
            return t
        }
        return {id:0, value:'errore ID non trovato in profileS'}
    },
    journal: (state) => (id) => {
        let t = state.journals.find(item => item.id == id)
        if(t){
            return JSON.parse(JSON.stringify(t))
        }
        return {id:0, value:'errore ID non trovato in profileS'}
    },
};