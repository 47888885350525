import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
    namespaced: true,
    state() {
        return {
            searchs: [
                {
                    id: 1,
                    title: 'search 1',
                    date_created: '2022-03-22T00:00:00+01:00',
                    form: {
                        comodity: "Regulatory Update Online | Food",
                        text_search: "testo 1",
                        years: ["2019"],
                        update_type: ["tipo 1"],
                        category_selected: ['category_1'],
                        countries_selected: [
                            {name: "italia", iso_code:'it'},
                            {name: "germania", iso_code:'de'},
                        ],
                        parametri_selected: ['parametri_1'],
                    }
                },
                {
                    id: 2,
                    title: 'search 2',
                    date_created: '2022-07-12T00:00:00+01:00',
                    form: {
                        comodity: "Regulatory Update Online | No Food",
                        text_search: "testo 2",
                        years: ["2019","2020"],
                        update_type: ["tipo 1","tipo 2"],
                        category_selected: ['category_2'],
                        countries_selected: [
                            {name: "italia", iso_code:'it'},
                            {name: "germania", iso_code:'de'},
                        ],
                        parametri_selected: ['parametri_2'],
                    }
                },
                {
                    id: 3,
                    title: 'search 3',
                    date_created: '2022-05-15T00:00:00+01:00',
                    form: {
                        comodity: "Regulatory Update Online | Food",
                        text_search: "testo 3",
                        years: ["2019","2021","2022"],
                        update_type: ["tipo 1","tipo 2","tipo 3"],
                        category_selected: ['category_2','category_3'],
                        countries_selected: [
                            {name: "italia", iso_code:'it'},
                            {name: "germania", iso_code:'de'},
                        ],
                        parametri_selected: ['parametri_2','parametri_3'],
                    }
                },
            ],
            sent_search: null
        };
    },
    actions,
    mutations,
    getters,
};
