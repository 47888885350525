export default {
    
    searchs(state){
        let t = state.searchs
        if(t){
            return t
        }
        return {id:0, value:'errore ID non trovato in profileS'}
    },
    search: (state) => (id) => {
        let t = state.searchs.find(item => item.id == id)
        if(t){
            return JSON.parse(JSON.stringify(t))
        }
        return {id:0, value:'errore ID non trovato in profileS'}
    },
    sentSearch(state){
        let t = state.sent_search
        console.log("getters t",t);

        return t
    },
};