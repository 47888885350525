<template>
   <div
      class="login--page"
      :style="'background: url(\'' + img_background + '\')'"
   >
      <div class="login--wrapper">
         <div class="row login--row">
            <div class="col-12 col-xl-6 login--col login--col__login">
               <div class="login--card">
                  <div class="login--img">
                     <img
                        src="https://assets-global.website-files.com/5e3177cecf36f6591e4e38cb/5ea2a86505e63bdd814cf868_Logo.png"
                     />
                  </div>
                  <v-text-field
                     label="E-mail"
                     outlined
                     dense
                     v-model="email"
                  ></v-text-field>
                  <v-text-field
                     label="Password"
                     type="password"
                     outlined
                     dense
                     v-model="password"
                  ></v-text-field>
                  <div class="row">
                     <div class="col-6">

                        <v-btn block @click="submitForm">Login</v-btn>
                     </div>
                     <div class="col-6">

                        <v-btn 
                        class="text-light"
                        color="yellow"
                        block
                        @click="
                              email = autoEmail;
                              password = autoPassword;
                        "
                           >AUTO</v-btn
                        >
                     </div>
                  </div>
                  <v-btn 
                    class="mt-2"
                     @click="
                        news = ['aaaaaaa', 'bbbbbbb', 'cccccccc', 'dddddddd']
                     "
                     >add</v-btn
                  >
                  <v-btn class="mt-2" @click="news = ''">rem</v-btn>

                  <v-progress-linear
                     v-if="isLoading"
                     color="primary"
                     indeterminate
                  ></v-progress-linear>

                  <div>
                     <v-alert type="error" @click="handleError" v-if="error">{{
                        error
                     }}</v-alert>
                  </div>
               </div>
            </div>
            <div
               v-if="news.length"
               class="d-none d-xl-flex col-xl-6 login--col login--col__news"
            >
               <div
                  v-for="(single_news, i) in news"
                  :key="single_news"
                  class="login--news"
               >
                  <p>{{ single_news }} {{ i }}</p>
                  <v-icon @click="removeNews(i)"
                     >mdi-close-circle-outline</v-icon
                  >
               </div>
            </div>
         </div>
      </div>
      <div style="position: absolute; top: 0; right: 0; z-index: 50">
         <ul>
            <li class="text-white">test@app.com</li>
            <li class="text-white">password</li>
         </ul>
      </div>
   </div>
</template>

<script>
export default {
   data() {
      return {
         img_background:
            "https://images.unsplash.com/photo-1653405001073-fb60feca4b88?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670",
         email: "",
         password: "",
         formIsValid: true,
         isLoading: false,
         error: null,
         news: ["testo 1"],

         autoEmail: "test@app.com",
         autoPassword: "password",
      };
   },
   computed: {},
   methods: {
      async submitForm() {
         this.formIsValid = true;
         if (
            this.email === "" ||
            !this.email.includes("@") ||
            this.password.length < 1
         ) {
            this.formIsValid = false;
            console.log("invalid form");
            return;
         }

         this.isLoading = true;

         const actionPayload = {
            email: this.email,
            password: this.password,
         };

         console.log("manual login");
         try {
            await this.$store.dispatch("login", actionPayload);
            const redirectUrl = this.$route.query.target ?? "/";
            this.$router.replace(redirectUrl);
         } catch (err) {
            this.error = err.message || "Failed to authenticate, try later.";
         }
         this.isLoading = false;
      },
      handleError() {
         this.error = null;
      },
      removeNews(index) {
         this.news.splice(index, 1);
      },
   },
};
</script>

<style lang="scss" scoped>
.login {
   &--page {
      background-size: cover;
      background-position: bottom;
      position: relative;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;

      &:after {
         @include AfterFullContainer();
         z-index: 1;
         background: rgba($black, 0.3);
      }
   }

   &--wrapper {
      position: relative;
      z-index: 4;
      max-width: 1200px;
      width: 100%;
   }

   &--row {
      justify-content: center;
      max-width: calc(100vw + 24px);
   }

   &--col {
     display: flex;
      justify-content: center;
      align-items: center;
      &__login {
         justify-content: flex-end;
      }
      &__news {
         justify-content: flex-start;
         align-items: flex-start;
         display: flex;
         flex-direction: column;
      }
      @media screen and (max-width: 1200px) {
         justify-content: center;
         align-items: center;
      }
   }

   &--news {
      position: relative;

      width: 500px;
      max-width: 90vw;
      display: flex;
      flex-direction: column;
      padding: 2rem;
      color: $black !important;

      border-radius: 6px;
      background: rgba(255, 255, 255, 0.65);

      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
      backdrop-filter: blur(11px);

      @include box-shadow();

      &:not(:first-child) {
         margin-top: 1rem;
      }

      .v-icon {
         position: absolute;
         top: 5px;
         right: 5px;
         opacity: 0.5;
         transition: opacity 0.3s ease;
         &:hover {
            opacity: 1;
         }
      }
   }

   &--card {
      position: relative;
      height: fit-content;
      width: 500px;
      max-width: 90vw;
      display: flex;
      flex-direction: column;
      padding: 2rem;
      padding-top: 5rem;
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.65);

      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
      backdrop-filter: blur(11px);

      @include box-shadow();
   }

   &--img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);

      display: flex;
      align-items: center;
      justify-content: center;

      overflow: hidden;
      border-radius: 100px;
      height: 75px;
      width: 75px;
      background: white;
      @include box-shadow();
      img {
         height: 80%;
         width: 80%;
      }
   }
}
.v-progress-linear {
   position: absolute;
   bottom: 0;
   left: 0;
   right: 0;
}
</style>