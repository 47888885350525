const TheModule = () => import("./TheModule.vue");
const PageSearch = () => import("./views/PageSearch.vue");

const moduleRoute = {
    path: '/search',
    component: TheModule,
    children: [
        {
          path: '/',
          component: PageSearch,
          meta: {
            requiresAuthenticated: true
          },
        },
      ]
};

export default router => {
  router.addRoutes([moduleRoute]);
};
