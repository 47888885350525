import moment from 'moment';
import router from '@/router/index'

export default {
    async addCollection(context){

        let last =  context.state.collections.at(-1)
        let id
        if(last){
            id = last.id
        } else{
            id = 0
        }

        console.log("pass");

        let new_item = {
            id: id+1,
            thumb: null,
            active: false,
            type: null,
            title: 'Titolo #' + moment(),
            description: '',
            updates: [],

            countries: [],
            category: [],
            parametri: [],
        };

        console.log("pass2",new_item);

        await new Promise(resolve => setTimeout(resolve, 2000)); //CAMBIO LOGICA CHIAMATA

        let rensonse = true //CAMBIO LOGICA CHIAMATA
        if(rensonse){ //CAMBIO LOGICA CHIAMATA
            context.commit('addItem',new_item)
            this.commit('setLoading', false);
            router.replace('/collections/edit/' + new_item.id)
            return new_item
        } //CAMBIO LOGICA CHIAMATA

        return 'error'
    },
    async editCollection (context,new_value) {
        await new Promise(resolve => setTimeout(resolve, 2000)); //CAMBIO LOGICA CHIAMATA

        console.log("editCollection");

        let rensonse = true //CAMBIO LOGICA CHIAMATA
        if(rensonse){ //CAMBIO LOGICA CHIAMATA
            context.commit('replaceItem',{ id: new_value.id , new_value:new_value})
            router.replace('/collections')
            this.commit('setLoading', false);
            return new_value
        } //CAMBIO LOGICA CHIAMATA

        return 'error'
    },

    async addUpdate (context,param) {
        await new Promise(resolve => setTimeout(resolve, 1000)); //CAMBIO LOGICA CHIAMATA

        let rensonse = true //CAMBIO LOGICA CHIAMATA
        if(rensonse){ //CAMBIO LOGICA CHIAMATA
            context.commit('addUpdate',param)
            this.commit('setLoading', false);
            return
        } //CAMBIO LOGICA CHIAMATA

        return 'error'
    },
    async removeUpdates (context,param) {
        await new Promise(resolve => setTimeout(resolve, 1000)); //CAMBIO LOGICA CHIAMATA

        console.log("action param",param);

        let rensonse = true //CAMBIO LOGICA CHIAMATA
        if(rensonse){ //CAMBIO LOGICA CHIAMATA
            context.commit('removeUpdates',param)
            this.commit('setLoading', false);
            return
        } //CAMBIO LOGICA CHIAMATA

        return 'error'
    },
};
