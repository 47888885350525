<template>
<div class="position-relative">
    <intro-account :user="user"></intro-account>
    <info-account :user="user"></info-account>
</div>
</template>

<script>

import IntroAccount from "./sections/IntroAccount.vue"
import InfoAccount from "./sections/InfoAccount.vue"


export default {
    components: {
        IntroAccount,
        InfoAccount
    },
    computed:{
        user(){
            let t = this.$store.getters['getUser']
            return t 
        }
    }
}
</script>

<style>
/*  */
</style>