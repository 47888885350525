<template>
   <div class="page-home--newsletter py-section">
      <div class="container-parallax">
         <v-parallax  src="@/assets/img/pages/home/bg-parallax.png"></v-parallax>
      </div>
      <div data-aos="fade-up" class="container-max-md position-relative" style="z-index: 10">
         <h2 class="title--line-x color-white mb-14">
            <span class="font-bold">{{ $t("home.advanced-search.title") }}</span><!-- RICERCHE AVANZATE -->
         </h2>

         <p class="text-center color-white mb-7">
            {{ $t("home.advanced-search.text") }}
            <!-- Imposta una ricerca multi-parametro, oppure accedi ad una ricerca salvata in precedenza per ripeterla con un solo clic. All’occorrenza potrai condividere le ricerche salvate con i tuoi colleghi e vedere quelle che loro hanno condiviso con te. Attenzione: i Regolamenti europei sono pubblicati in “Unione Europea”. Se sei interessato ad una visione completa di tutti gli aggiornamenti normativi che impattano su un Paese membro UE, ti suggeriamo di impostare una ricerca che includa sia Unione Europea sia il Paese membro UE. -->
         </p>

         <div class="text-center">
            <v-btn
               to="/search"
               class="px-15"
               color="white"
               outlined
            >  
               {{ $t("btn.advanced-search") }}
               <!-- Vai alle <b class="ml-2">RCERCHE AVANZATE</b> -->
            </v-btn>
         </div>
      </div>
   </div>
</template>

<script>
export default {};
</script>

<style>
/*  */
</style>