<template>
   <div class="page-home--redirect py-section">
      <div class="blob-1"><img src="@/assets/img/texture/blob-1.png" alt=""></div>
      <div class="blob-3"><img src="@/assets/img/texture/blob-2.png" alt=""></div>

      <div class="container mt-5" style="min-height: 450px">
         <div class="grid gap-10 grid-cols-12">
            <div class="col-span-5 d-flex justify-content-center align-items-center">
               <div class="comp">
                  <div class="comp--item comp--item__1" :class="{'active': selectedSection == 'collections'}">
                     <v-responsive :aspect-ratio="1 / 1" class="comp--square"  @click="selectedSection = 'collections'">
                        <v-icon
                           style="font-size: 32px"
                           color="white"
                           class="mb-3"
                           large
                           >mdi-star-outline</v-icon
                        >
                        <p class="color-white font-bold mb-0">
                           {{ $t("home.redirect.collection.square") }}
                           <!-- Visualizza le tue <br />
                           collezioni -->
                        </p>
                     </v-responsive>
                  </div>
                  <div class="comp--item comp--item__2" :class="{'active': selectedSection == 'journal'}">
                     <v-responsive :aspect-ratio="1 / 1" class="comp--square"  @click="selectedSection = 'journal'">
                        <v-icon
                           color="white"
                           class="mb-3"
                           large
                        >mdi-bell-outline</v-icon>
                        <p class="color-white font-bold mb-0">
                           {{ $t("home.redirect.journals.square") }}
                           <!-- Vai ai tuoi <br />
                           journals -->
                        </p>
                     </v-responsive>
                  </div>
                  <div class="comp--item comp--item__3" :class="{'active': selectedSection == 'countries'}">
                     <v-responsive :aspect-ratio="1 / 1" class="comp--square"  @click="selectedSection = 'countries'">
                        <v-icon
                           color="white"
                           class="mb-3"
                           large
                        >mdi-earth</v-icon>
                        <p class="color-white font-bold mb-0">
                           {{ $t("home.redirect.countries.square") }}
                           <!-- Vai ai <br />
                           Paesi -->
                        </p>
                     </v-responsive>
                  </div>
                  <div class="comp--item comp--item__4" :class="{'active': selectedSection == 'ricerca'}">
                     <v-responsive :aspect-ratio="1 / 1" class="comp--square"  @click="selectedSection = 'ricerca'">
                        <v-icon
                           color="white"
                           class="mb-3"
                           large
                        >mdi-archive-search-outline</v-icon>
                        <p class="color-white font-bold mb-0">
                           {{ $t("home.redirect.search.square") }}
                           <!-- Ricerca <br />
                           avanzata -->
                        </p>
                     </v-responsive>
                  </div>
               </div>
            </div>
            <div class="col-span-7">
               <div class="ml-7">
                  <div class="page-home--redirect--tab-container">
                     <div class="page-home--redirect--tab" :class="{'selected': selectedSection ==='collections'}">
                        <h3 class="title--underline font-bold color-primary mb-4">{{ $t("home.redirect.collections.title") }}<!-- Collezioni --></h3>
                        <p>
                           Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reprehenderit explicabo quod saepe laboriosam. Incidunt, delectus commodi. Tenetur voluptatibus molestiae illo consequatur rem laudantium debitis quasi qui, est ullam beatae magni?
                           Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reprehenderit explicabo quod saepe laboriosam. Incidunt, delectus commodi. Tenetur voluptatibus molestiae illo consequatur rem laudantium debitis quasi qui, est ullam beatae magni?
                           Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reprehenderit explicabo quod saepe laboriosam. Incidunt, delectus commodi. Tenetur voluptatibus molestiae illo consequatur rem laudantium debitis quasi qui, est ullam beatae magni?
                        </p>
                        <v-btn
                           class="mt-5"
                           to="/collections"
                           color="primary"
                           outlined
                        >
                           Vai alle tue<b class="ml-2">COLLEZIONI</b>
                        </v-btn>
                        
                     </div>
                     <div class="page-home--redirect--tab" :class="{'selected': selectedSection ==='journal'}">
                        <h3 class="title--underline font-bold color-primary mb-4">{{ $t("home.redirect.countries.square") }}Journals</h3>
                        <p>
                           Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reprehenderit explicabo quod saepe laboriosam. Incidunt, delectus commodi. Tenetur voluptatibus molestiae illo consequatur rem laudantium debitis quasi qui, est ullam beatae magni?
                           Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reprehenderit explicabo quod saepe laboriosam. Incidunt, delectus commodi. Tenetur voluptatibus molestiae illo consequatur rem laudantium debitis quasi qui, est ullam beatae magni?
                           Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reprehenderit explicabo quod saepe laboriosam. Incidunt, delectus commodi. Tenetur voluptatibus molestiae illo consequatur rem laudantium debitis quasi qui, est ullam beatae magni?
                        </p>
                        <v-btn
                           class="mt-5"
                           to="/journal"
                           color="primary"
                           outlined
                        >
                           Vai ai tuoi <b class="ml-2">JOURNALS</b>
                        </v-btn>
                        
                     </div>
                     <div class="page-home--redirect--tab" :class="{'selected': selectedSection ==='countries'}">
                        <h3 class="title--underline font-bold color-primary mb-4">{{ $t("home.redirect.countries.square") }}Paesi</h3>
                        <p>
                           Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reprehenderit explicabo quod saepe laboriosam. Incidunt, delectus commodi. Tenetur voluptatibus molestiae illo consequatur rem laudantium debitis quasi qui, est ullam beatae magni?
                           Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reprehenderit explicabo quod saepe laboriosam. Incidunt, delectus commodi. Tenetur voluptatibus molestiae illo consequatur rem laudantium debitis quasi qui, est ullam beatae magni?
                           Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reprehenderit explicabo quod saepe laboriosam. Incidunt, delectus commodi. Tenetur voluptatibus molestiae illo consequatur rem laudantium debitis quasi qui, est ullam beatae magni?
                        </p>
                        <v-btn
                           class="mt-5"
                           to="/countries"
                           color="primary"
                           outlined
                        >
                           Vai ai <b class="ml-2">PAESI</b>
                        </v-btn>
                        
                     </div>
                     <div class="page-home--redirect--tab" :class="{'selected': selectedSection ==='ricerca'}">
                        <h3 class="title--underline font-bold color-primary mb-4">{{ $t("home.redirect.countries.square") }}Ricerca</h3>
                        <p>
                           Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reprehenderit explicabo quod saepe laboriosam. Incidunt, delectus commodi. Tenetur voluptatibus molestiae illo consequatur rem laudantium debitis quasi qui, est ullam beatae magni?
                           Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reprehenderit explicabo quod saepe laboriosam. Incidunt, delectus commodi. Tenetur voluptatibus molestiae illo consequatur rem laudantium debitis quasi qui, est ullam beatae magni?
                           Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reprehenderit explicabo quod saepe laboriosam. Incidunt, delectus commodi. Tenetur voluptatibus molestiae illo consequatur rem laudantium debitis quasi qui, est ullam beatae magni?
                        </p>
                        <v-btn
                           class="mt-5"
                           to="/search"
                           color="primary"
                           outlined
                        >
                           Vai alle <b class="ml-2">RICERCHE</b>
                        </v-btn>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   data() {
      return {
         selectedSection: 'collections'
      }
   },
};
</script>

<style lang="scss" >
.comp {
   display: flex;
   flex-wrap: wrap;
   width: 100%;
   &--item {
      &:nth-child(1) {
         margin-bottom: -24%;
         width: 100%;
         .comp--square {
            background: #0F2F58;
            z-index: 2;
            margin: auto;
            width: 50%;
            transform-origin: bottom;

            &:hover{
               background: #144079;
            }
         }
      }
      &:nth-child(2) {
         width: 50%;
         transform: translateX(-5px);
         .comp--square{
            background: #155887;
            transform-origin: right;

            &:hover{
               background: #1a6fab;
            }
         }
      }
      &:nth-child(3) {
         width: 50%;
         transform: translateX(5px);
         .comp--square{
            background: #1C82B8;
            transform-origin: left;

            &:hover{
               background: #2199d9;
            }
         }
         
      }
      &:nth-child(4) {
         width: 100%;
         margin-top: -24%;
         .comp--square {
            background: #23ABE7;
            margin: auto;
            width: 50%;
            transform-origin: top;

            &:hover{
               background: #26beff;
            }
         }
      }

      &.active{
         .comp--square {
            transform: scale(1.15);
         }
      }
      
   }
   &--square {
      overflow: hidden;
      clip-path: polygon(50% -0.5%, 100.5% 50%, 50% 100.5%, -0.5% 50%);

      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      transition:
         background .2s ease,
         transform .2s ease
      ;
      cursor: pointer;

      p {
         text-transform: uppercase;
         font-weight: 700;
         font-size: 1.4rem;
         line-height: 16px;
      }
   }
}

.page-home{
   &--redirect{
      &--tab{
         position: absolute;
         top: 0;
         left: -30px;
         width: 100%;
         opacity: 0;
         transition: 
            left .3s ease,
            opacity .2s ease
         ;
         pointer-events: none;

         &.selected{
            pointer-events: all;
            opacity: 1;
            left: 0;
            transition: 
               opacity .3s ease,
               left .3s ease
            ;
         }      
      }
      &--tab-container{
         position: relative;
      }
   }
}
</style>