import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
    namespaced: true,
    state() {
        return {
            journals: [
                {
                    id: 1,
                    active: true,
                    title: 'Nome catalogo - Dweek',
                    description: 'Lorem 1 ipsum dolor sit amet consectetur adipisicing elit. Aliquid ratione optio corrupti officia. Voluptatem recusandae labore iste nulla saepe dolorum, quos excepturi blanditiis, eos repellat error eligendi esse ad optio!',
                    periodics: [
                        {
                            id: 1,
                            dateStart: '2022-09-01T00:00:00+01:00',
                            dateEnd: '2022-09-30T00:00:00+01:00',
                            changed: false,
                            updates: [1,2,3],
                        },
                        {
                            id: 2,
                            dateStart: '2022-10-01T00:00:00+01:00',
                            dateEnd: '2022-10-30T00:00:00+01:00',
                            changed: false,
                            updates: [1,2],
                        },
                        {
                            id: 3,
                            dateStart: '2022-11-01T00:00:00+01:00',
                            dateEnd: '2022-11-30T00:00:00+01:00',
                            changed: true,
                            updates: [1,2],
                        },
                    ],

                    countries: [
                        {name: "italia", iso_code:'it'},
                    ],
                    category: ['category_1','category_3'],
                    parametri: ['parametri_1','parametri_3'],

                    periodicity: 'week',
                    type: 'preregulatory',
                },
                {
                    id: 2,
                    active: false,
                    title: '222222 lorme 22',
                    description: 'Lorem 2 ipsum dolor sit amet consectetur adipisicing elit. Aliquid ratione optio corrupti officia. Voluptatem recusandae labore iste nulla saepe dolorum, quos excepturi blanditiis, eos repellat error eligendi esse ad optio!',

                    periodics: [
                        {
                            id: 1,
                            dateStart: '2022-09-01T00:00:00+01:00',
                            dateEnd: '2022-09-30T00:00:00+01:00',
                            changed: false,
                            updates: [1],
                        },
                        {
                            id: 2,
                            dateStart: '2022-10-01T00:00:00+01:00',
                            dateEnd: '2022-10-30T00:00:00+01:00',
                            changed: true,
                            updates: [1,2],
                        },
                        {
                            id: 3,
                            dateStart: '2022-11-01T00:00:00+01:00',
                            dateEnd: '2022-11-30T00:00:00+01:00',
                            changed: true,
                            updates: [1,2,3],
                        },
                    ],

                    countries: [
                        {name: "italia", iso_code:'it'},
                    ],
                    category: ['category_1','category_3'],
                    parametri: ['parametri_1','parametri_3'],

                    periodicity: 'day',
                    type: 'all',
                }
            ]
        };
    },
    actions,
    mutations,
    getters,
};
