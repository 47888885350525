<template>
   <div class="page-intro">

     <img class="page-home--intro-map page-home--intro-map__est" src="@/assets/img/pages/home/map-est.png" alt="">
     <img class="page-home--intro-map page-home--intro-map__ovest" src="@/assets/img/pages/home/map-ovest.png" alt="">

     <div class="container-max-md text-center  text-container">
        <h1 class="font-bold color-primary mb-2">Regulatory Update</h1>
        <h3 class="color-primary">{{$t("INIZIA LA TUA RICERCA")}}</h3>
        <p class="mt-5">
          {{$t("home.intro.text")}}
        </p>
        <v-btn
          :to="'/search'"
          color="quaternary"
          class="mt-3 px-3"
        > 
          <v-icon>mdi-magnify</v-icon>
          <span class="d-inline-block ml-1 mt-[3px]">
            {{$t("btn.comincia-ricerca")}}
            
            
          </span>
        </v-btn>
     </div>
   </div>
</template>

<script>
export default {
  methods: {
    showLoading() {
      this.mainShowLoading()
    },
  },
};
</script>

<style lang="scss" scoped>
/*  */

</style>