export default {
    countries(state){
        let t = state.countries
        if(t){
            return t
        }
        return {id:0, value:'errore ID non trovato in profileS'}
    },
    country: (state) => (id) => {
        let t = state.countries.find(item => item.id == id)
        if(t){
            return JSON.parse(JSON.stringify(t))
        }
        return {id:0, value:'errore ID non trovato in profileS'}
    },

    frames: (state) => (ids) => {

        let frames = []
        if(ids.length){
            ids.forEach(id => {
                let t = state.framework_info.find(el => el.id == id)
                if(t){
                    frames.push(t)
                }
            })
    
            if(frames){
                return JSON.parse(JSON.stringify(frames))
            }
        }
        return {id:0, value:'errore ID non trovato in profileS'}
    },
    frame: (state) => (id) => {
        let t = state.framework_info.find(item => item.id == id)
        if(t){
            return JSON.parse(JSON.stringify(t))
        }
        return {id:0, value:'errore ID non trovato in profileS'}
    },

    continents(state){
        let t = state.continents
        if(t){
            return t
        }
        return {id:0, value:'errore ID non trovato in profileS'}
    },
    continent: (state) => (id) => {
        let t = state.continents.find(item => item.id == id)
        if(t){
            return JSON.parse(JSON.stringify(t))
        }
        return {id:0, value:'errore ID non trovato in profileS'}
    },

    selectableCountries(state){
        let t = []
        state.countries.forEach(country => {
            let rep = {
                id: country.id,
                label: country.label,
                value: {
                    name: country.name,
                    iso_code: country.iso_code
                }
            }
            t.push(rep)
        })

        if(t){
            return t
        }
        return {id:0, value:'errore ID non trovato in profileS'}
    },
};