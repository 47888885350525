var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"latest-news"},[_c('div',{staticClass:"container overflow-hidden",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"latest-news--header-box"},[_c('h4',{staticClass:"latest-new--title"},[_vm._v(_vm._s(_vm.$t("home.news.title")))]),_c('p',{staticClass:"latest-new--subtitle"},[_vm._v(_vm._s(_vm.$t("home.news.subtitle")))])]),_c('carousel',{staticClass:"carousel carousel--top-news",attrs:{"loop":false,"rewind":true,"dots":true,"nav":false,"margin":18,"autoplay":true,"autoplayHoverPause":true,"stagePadding":0,"startPosition":1,"responsive":{
           0:{
               items: 1,
           },
           840:{
               items: 3,
           }
         }}},_vm._l((_vm.news),function(item_new){return _c('card-news',{key:item_new.id,attrs:{"news":item_new}})}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }