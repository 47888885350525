<template>
    <div v-if="updates && updates.length" class="updates-table">
        
        <div class="flex justify-between align-center gap-5 w-100 p-[1rem_2rem] updates-table--row color-primary font-bold">
            <div class="grow">{{ $t("table.nome") }}:</div>
            <div class="w-[250px] max-w-[40%]">{{ $t("table.categorie") }}:</div>
            <div class="w-[130px] max-w-[40%]">{{ $t("table.data-uscita") }}:</div>
        </div>
        <div
            v-for="update in updates"
            :key="update.id"
            class="flex justify-between align-center gap-[1.5rem] w-100 p-[1rem_2rem] updates-table--row"
        >
            <div class="grow">
                <modal-update :type="'table'" :update="update"></modal-update>
            </div>
            <div class="flex gap-2 w-[250px] max-w-[40%]">
                <span > <!-- v-for="category in update.category" :key="category" -->
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                class="tag-icon bg-red color-white"
                                v-bind="attrs"
                                v-on="on"
                                small
                            >mdi-pencil</v-icon>
                        </template>
                        <span>pencil</span>
                    </v-tooltip>
                </span>

                <!-- CANCELLA -->
                <span > 
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                class="tag-icon bg-green color-white"
                                v-bind="attrs"
                                v-on="on"
                                small
                            >mdi-barley</v-icon>
                        </template>
                        <span>barley</span>
                    </v-tooltip>
                </span>
                <span > 
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                class="tag-icon bg-blue-500 color-white"
                                v-bind="attrs"
                                v-on="on"
                                small
                            >mdi-home</v-icon>
                        </template>
                        <span>home</span>
                    </v-tooltip>
                </span>
                <!-- CANCELLA -->
            </div>
            <div class="w-[130px] max-w-[40%]">
                {{ [update.date] | moment("DD/MM/YYYY") }}
            </div>
        </div>
    </div>
    <div v-else>
        non ci sono update
    </div>
</template>

<script>
import ModalUpdate from "@/components/modals/ModalUpdate.vue"
export default {
    props: ['updates'],
    components: {
        ModalUpdate,
    },
}
</script>

<style lang="scss">
    .table-update:not(.not){
        & > .v-data-table__wrapper > table > thead > tr > th:not(.not){
            color: $primary;
            font-weight: bold;
        }

        & > .v-data-table__wrapper > table > thead > tr > th:not(.not),
        & > .v-data-table__wrapper > table > tbody > tr > td:not(.not){
            font-size: 1.6rem;

            &:last-child{
                text-align: right;
            }
        }

        .table-update--title{
            
            /* width: 50%;
            overflow: hidden; */
            a{
                color: $black;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

        }

        tr:hover{
            .table-update--title a{
                color: $primary;
            }
        }
    }

    .updates-table{
        & > *{
            border-bottom: 1px solid $border-color;
        }

        &--row{


            & > *:first-child{
                width: 0;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            & > *:last-child{
                text-align: right;
            }
        }
    }
</style>