import { render, staticRenderFns } from "./TheCatalogs.vue?vue&type=template&id=7aeaa005&"
import script from "./TheCatalogs.vue?vue&type=script&lang=js&"
export * from "./TheCatalogs.vue?vue&type=script&lang=js&"
import style0 from "./TheCatalogs.vue?vue&type=style&index=0&id=7aeaa005&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VParallax } from 'vuetify/lib/components/VParallax';
installComponents(component, {VBtn,VParallax})
