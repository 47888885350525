<template>
    <v-dialog
        :persistent="true"
        v-model="dialog"
        width="90vw"
        content-class="h-[90vh]"
    >
      <template v-slot:activator="{ on, attrs }">
        <a
          :class="'trigger-link--' + type"
          v-bind="attrs"
          v-on="on"
        >
          {{update.title}}
        </a>
      </template>

      <div class="modal-update relative flex">

          <a
            @click="dialog = false"
            class="modal-close-icon"
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </a>

          <div class="modal-update--categorie--container flex flex-col gap-5">
            <div class="modal-update--categorie bg-[#CF891A] color-white">
              <v-icon color="white" class="mr-1">mdi-barley</v-icon>
              <span>Grano</span>
            </div>
            <div class="modal-update--categorie  bg-green-500 color-white">
              <v-icon color="white" class="mr-1">mdi-leaf</v-icon>
              <span>Verdure</span>
            </div>
          </div>

          <div class="modal-update--container grow flex flex-col min-h-[90vh]">

            <h3 class="title--underline font-bold color-primary max-w-80 mb-5">{{update.title}}</h3>
            
            <div>
              <v-icon>mdi-calendar</v-icon>
              {{ [update.date] | moment("DD/MM/YYYY") }}
            </div>

            <div class="grow">
              <div class="border-list mt-7">
                <div v-if="update.text" class="border-list--item">
                  <p class="color-primary font-bold">Text</p>
                  <p class="grow">
                    {{update.text}}
                  </p>
                </div>
                <div class="border-list--item">
                  <p class="color-primary font-bold">Nome</p>
                  <p class="grow">
                    {{update.date}}
                  </p>
                </div>
                <div class="border-list--item">
                  <p class="color-primary font-bold">Nome</p>
                  <p class="grow">
                    {{update.title}}
                  </p>
                </div>
                <div class="border-list--item">
                  <p class="color-primary font-bold">Nome</p>
                  <p class="grow">
                    {{update.title}}
                  </p>
                </div>
              </div>
              
              <div class="mt-7">
                <h6 class="color-primary font-bold mb-2">Sintesi:</h6>
                <p>{{update.text}}</p>
              </div>
            </div>
            

            <div class="flex flex-col align-end mt-15">
              <!-- <modal-preferite :type="'card'" :update="update"></modal-preferite> -->
              <modal-consulenza :type="'card'" :update="update"></modal-consulenza>
              <modal-collections :update="update"></modal-collections>
              
              <a href="#" class="modal-update--control modal-update--control__notifica disabled">
                <v-icon>mdi-bell</v-icon>
                Imposta una notifica 30 giorni prima
                <small class="modal-update--control__notifica__forecast">24 Aprile 2022</small>
              </a>
            </div>

          </div>

        </div>
    </v-dialog>
</template>

<script>
  // import ModalPreferite from "@/components/modals/ModalPreferite.vue"
  import ModalConsulenza from "@/components/modals/ModalConsulenza.vue"
  import ModalCollections from "@/components/modals/ModalCollections.vue"
  export default {
    props: ["type","update"],
    components: {
        // ModalPreferite,
        ModalConsulenza,
        ModalCollections,
    },
    data () {
      return {
        dialog: false,
      }
    },
  }
</script>

<style lang="scss">
  .trigger-link{

    &--card{
      display: -webkit-box;
  
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
  
      font-weight: bold;
      color: $primary;
    }
    &--table:not(.not){
      display: block;
      width: 750px;

      color: $black;

      &:hover{
        color: $primary;
      }

    }
  }

  .modal-update{
    background: white;
    min-height: 100%;
    max-height: 100%;
    overflow-y: scroll;

    &--container{
      padding: 5rem;
      padding-left: 5rem;
      height: fit-content;
    }

    &--categorie{
      width: fit-content;
      padding: .5rem 4rem .5rem .5rem;
      clip-path: polygon(100% 0%, calc(100% - 24px) 50%, 100% 100%, 0% 100%, 0% 0%);
      max-width: 300px;
      display: flex;
      flex-wrap: nowrap;
      
      &--container{
        position: sticky;
        top: 0;
        margin-left: 0;
        padding-top: 50px;
      }
    }

    &--control{
      border-bottom: 1px solid;
      padding-bottom: .2rem;
      padding-left: 1rem;
      transition: transform .3s ease;

      &:not(:first-child){
        margin-top: 1rem;
      }

      &:hover{
        transform: translateY(-2px)
      }

      &__preferiti:not(.not){
        color: #CFA143;
        border-color: #CFA143;
        & > .v-icon{
          color: #CFA143;
        }
      }
      &__consulenza:not(.not){
        color: #0F2F58;
        border-color: #0F2F58;
        & > .v-icon{
          color: #0F2F58;
        }
      }
      &__catalogo:not(.not){
        color: #97C571;
        border-color: #97C571;
        & > .v-icon{
          color: #97C571;
        }
      }
      &__notifica:not(.not){
        position: relative;
        color: #E3D02F;
        border-color: #E3D02F;
        & > .v-icon{
          color: #E3D02F;
        }

        &.disabled{
          pointer-events: none;
          color: grey;
          border-color: grey;
          & > .v-icon{
            color: grey;
          }
        }

        .modal-update--control__notifica__forecast{
          color: $black;
          position: absolute;
          white-space: nowrap;
          bottom: 0;
          right: 0;
          transform: translateY(120%);
        }
      }
    }
  }
  
</style>