const TheModule = () => import("./TheModule.vue");
const PageCountries = () => import("./views/PageCountries.vue");
const PageCountry = () => import("./views/PageCountry.vue");
const CountryFrame = () => import("./views/CountryFrame.vue");

/* const PageOne = () => import("./views/sections/PageOne.vue");
const PageTwo = () => import("./views/sections/PageTwo.vue"); */

const moduleRoute = {
    path: '/countries',
    component: TheModule,
    children: [
      {
        path: '/',
        component: PageCountries,
        meta: {
          requiresAuthenticated: true
        },
      },
      {
        path: ":id/:type",
        props: true,
        component: PageCountry,
        meta: {
          requiresAuthenticated: true
        },
      },
      {
        path: ":id/frame/:frame_id",
        props: true,
        component: CountryFrame,
        meta: {
          requiresAuthenticated: true
        },
      }
    ]
};

export default router => {
  router.addRoutes([moduleRoute]);
};
