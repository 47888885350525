import moment from 'moment';

export default {
    async editJournal (context,new_value) {
        await new Promise(resolve => setTimeout(resolve, 2000)); //CAMBIO LOGICA CHIAMATA

        let rensonse = true //CAMBIO LOGICA CHIAMATA
        if(rensonse){ //CAMBIO LOGICA CHIAMATA
            context.commit('replaceItem',{ id: new_value.id , new_value:new_value})
            return new_value
        } //CAMBIO LOGICA CHIAMATA

        return 'error'
    },
    async addJournal (context) {

        let last =  context.state.journals.at(-1)
        let id
        if(last){
            id = last.id
        } else{
            id = 0
        }

        let new_item = {
            id: id+1,
            active: false,
            title: 'journals #' + moment(),
            type: 'all',
            periodicity: 'week',
            countries: [],
            category: [],
            parametri: [],
        }

        await new Promise(resolve => setTimeout(resolve, 2000)); //CAMBIO LOGICA CHIAMATA

        let rensonse = true //CAMBIO LOGICA CHIAMATA
        if(rensonse){ //CAMBIO LOGICA CHIAMATA
            context.commit('addItem',new_item)
            return new_item
        } //CAMBIO LOGICA CHIAMATA

        return 'error'
    },
    async deleteJournal(context, item){
        await new Promise(resolve => setTimeout(resolve, 2000)); //CAMBIO LOGICA CHIAMATA
        let rensonse = true //CAMBIO LOGICA CHIAMATA
        if(rensonse){ //CAMBIO LOGICA CHIAMATA
            context.commit('deleteItem',item)
        } //CAMBIO LOGICA CHIAMATA

        return 'error'
    },
};
