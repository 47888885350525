<template>
<div class="container my-section">
    <card-collection
        data-aos="fade-up"
        v-for="collection in collections" :key="collection.id"
        :collection="collection"
    ></card-collection>
    <div class="flex justify-center mt-section">
        <v-btn
            class="px-10"
            color="primary"
            elevation="2"
            @click="createCollection"
        >
            Aggiungi collezione
        </v-btn>
    </div>
</div>
</template>

<script>
import CardCollection from "./CardCollection.vue"

export default {
    components:{
        CardCollection
    },
    computed: {
        collections() {
            return this.$store.getters['collections/collections']
        }
    },
    methods: {
        createCollection() {
            this.setLoading(true)
            this.$store.dispatch("collections/addCollection");

            /* this.$router.replace('/catalogs/edit/' + a.id); */
        },
    },
}
</script>

<style lang="scss" scoped>
/*  */
</style>