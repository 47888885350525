<template>
<div class="position-relative">
    <intro-pages :title="'Regulatory Agenda'">
        <p>
            Scopri quali norme sono entrate in vigore o entreranno in vigore nell’intervallo temporale di tuo interesse.
        </p>
        <br>
        <p>
            <b class="color-primary">Attenzione:</b> i Regolamenti europei sono pubblicati in “Unione Europea”. Se sei interessato ad una visione completa di tutti gli aggiornamenti normativi che impattano su un Paese membro UE, ti suggeriamo di impostare una ricerca avanzata che includa sia Unione Europea sia il Paese membro UE.
        </p>
    </intro-pages>
    <search-agenda data-aos="fade-up"></search-agenda>
    <list-updates data-aos="fade-up"></list-updates>
</div>
</template>

<script>
import IntroPages from "@/components/IntroPages.vue"
import SearchAgenda from "./sections/SearchAgenda.vue"
import ListUpdates from "@/components/ListUpdates.vue"

export default {
    components: {
        IntroPages,
        SearchAgenda,
        ListUpdates,
    },
}
</script>

<style lang="scss" scoped>
/*  */
</style>