import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#0A4479',
                secondary: '#02ABE7',
                tertiary: '#E8EDF3',
                quaternary: '#133C6C',
                accent: '#8c9eff',
                error: '#CF4537',

                lightBlue: '#358AFA',
                green: colors.green.darker3,
                red: colors.red.darken3,
                yellow: '#DCAE3B',
                grey: '#666666',
            },
        },
        breakpoint: {
            thresholds: {

              /* xs: 0,
              sm: 576,
              md: 768,
              lg: 992,
              xl: 1200,
              xxl: 1400, */

                sm: 540,
                md: 840,
                lg: 960,
                xl: 1140,
                xxl: 1320,


            },
            scrollBarWidth: 24,
          },
    },
});
