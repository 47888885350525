/* import * as types from "./mutation-types"; */

export default {
    addItem(state, new_item){
        if(new_item){
            console.log("new",new_item);
            state.collections.push(new_item)

            console.log(state.collections);
            return
        }
        console.log("ERRORE MUTATION catalogs -> addItem");
        return
    },
    replaceItem(state, {id, new_value}){
        if(id && new_value){
            let index = state.collections.findIndex(el => el.id == id)
            state.collections[index] = new_value

            console.log("new_value",new_value);
            console.log("state.collections[index]",state.collections[index]);
            return
        }
        console.log("ERRORE MUTATION collections -> replaceItem");
        return
    },
    addUpdate(state, params){
        if(params.update_id && params.collection_id){
            let index = state.collections.findIndex(el => el.id == params.collection_id)
            state.collections[index].updates.push(params.update_id)
            return
        }
        console.log("ERRORE MUTATION collections -> replaceItem");
        return
    },
    removeUpdates(state, params){

        console.log("removeUpdates",params);
        console.log("removeUpdates",params.updates.length);
        console.log("removeUpdates",params.id_collection);

        if(params.updates.length && params.id_collection){

            let index = state.collections.findIndex(el => el.id == params.id_collection)
            console.log("index state.collections",index);
            console.log("state.collections[index]",state.collections[index]);
            console.log("state.collections[index].updates",state.collections[index].updates);

            params.updates.forEach(upd_id => {
                console.log("upd_id",upd_id);
                let index_upd = state.collections[index].updates.findIndex(el => el == upd_id)
                console.log("index_upd",index_upd);
                
                // .splice(i, 1)
                if(index_upd != -1){
                    console.log("1 state.collections[index].updates",state.collections[index].updates);
                    state.collections[index].updates.splice(index_upd, 1)
                    console.log("2 state.collections[index].updates",state.collections[index].updates);
                }
            });

            
            
            return
        }
        console.log("ERRORE MUTATION collections -> replaceItem");
        return
    },
};
