export default {
    profileId(state) {
      return state.authenticated_profile.profileId;
    },
    token(state) {
      return state.authenticated_profile.token;
    },
    didAutoLogout(state) {
      return state.did_auto_logout;
    },
    isAuthenticated(state){
      return !!state.authenticated_profile;
    },
    loadingPage(state){
      return state.loadingPage;
    },
    profileCan(state, intention, resource) {
      if( !state.getters.isAuthenticated )
        return false;
      console.log(intention);
      console.log(resource);
      // if( !state.acl_map.hasOwnProperty(state.authenticated_profile.role) )
      //   return false;

      // if( !state.acl_map[state.authenticated_profile.role].hasOwnProperty(resource) ) 
      //   return false;

      // if( !state.acl_map[state.authenticated_profile.role][resource].includes(intention) ) 
      //   return false;

      return true;
    },
    getUser(state){
      let t = state.user
        if(t){
            return t
        }
        return {id:0, value:'errore ID non trovato in user'}
    },
  };