var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"flex justify-between align-end list-updates--header"},[(_vm.passed_title)?_c('div',[_c('h3',{staticClass:"color-primary font-bold"},[_vm._v(_vm._s(_vm.passed_title))])]):_c('div',[_c('span',[_vm._v(_vm._s(_vm.updates.length)+" elementi ")])]),_c('div',{staticClass:"flex gap-2 list-updates--controls"},[(_vm.checkbox_abilitate && _vm.collection)?_c('v-tooltip',{attrs:{"open-delay":_vm.tooltip_open_delay,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"inline-block"},[(_vm.update_checked.length == 0)?_c('v-btn',_vm._g(_vm._b({staticClass:"disabled",attrs:{"color":"white","elevation":"3","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-archive-arrow-up-outline")])],1):_c('v-btn',_vm._g(_vm._b({staticClass:"bg-red",attrs:{"color":"white","elevation":"3","icon":""},on:{"click":_vm.EliminateToCollection}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-archive-arrow-up-outline")])],1)],1)]}}],null,false,1510413951)},[_c('div',{staticClass:"max-w-[200px]"},[_c('p',[_vm._v("Elimina dalla collezione")])])]):_vm._e(),(_vm.checkbox_abilitate)?_c('v-tooltip',{attrs:{"open-delay":_vm.tooltip_open_delay,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"inline-block pr-[1.3rem] mr-[calc(1.3rem-0.5rem)]",staticStyle:{"border-right":"1px solid black"}},[(_vm.update_checked.length == 0)?_c('v-btn',_vm._g(_vm._b({staticClass:"disabled",attrs:{"color":"white","elevation":"3","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1):_c('v-btn',_vm._g(_vm._b({staticClass:"bg-green",attrs:{"color":"white","elevation":"3","icon":""},on:{"click":_vm.downloadUpdates}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)],1)]}}],null,false,97398716)},[_c('div',{staticClass:"max-w-[200px]"},[_c('p',[_vm._v("Download")])])]):_vm._e(),_c('v-tooltip',{attrs:{"open-delay":_vm.tooltip_open_delay,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"bg-primary",attrs:{"color":"white","elevation":"3","icon":""},on:{"click":function($event){_vm.checkbox_abilitate = !_vm.checkbox_abilitate}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-checkbox-marked-outline")])],1)]}}])},[_c('div',{staticClass:"max-w-[200px]"},[_c('p',[_vm._v("Abilita selezione multipla")])])]),(!_vm.disable_change_mode)?_c('v-tooltip',{attrs:{"open-delay":_vm.tooltip_open_delay,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.grid_mode)?_c('v-btn',_vm._g(_vm._b({staticClass:"bg-primary",attrs:{"color":"white","elevation":"3","icon":""},on:{"click":function($event){_vm.grid_mode = !_vm.grid_mode}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1):_c('v-btn',_vm._g(_vm._b({staticClass:"bg-primary",attrs:{"color":"white","elevation":"3","icon":""},on:{"click":function($event){_vm.grid_mode = !_vm.grid_mode}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-grid")])],1)]}}],null,false,1112723469)},[_c('div',{staticClass:"max-w-[200px]"},[_c('p',[_vm._v("Cambio visualizazione")])])]):_vm._e()],1)]),(_vm.updates.length)?_c('div',[_c('div',{staticClass:"grid grid-cols-2 gap-10 mt-3 "},_vm._l((_vm.updates),function(update){return _c('div',{key:update.id,staticClass:"col-span-2 card-update--container ",class:{'col-span-1 card-update--container__grid-mode': _vm.grid_mode}},[_c('card-update',{attrs:{"update":update,"checkbox_abilitate":_vm.checkbox_abilitate,"reset":_vm.reset},on:{"checkboxChange":_vm.checkboxChange}})],1)}),0)]):_c('div',{staticClass:"py-section"},[_vm._v(" non ci sono update ")])])}
var staticRenderFns = []

export { render, staticRenderFns }