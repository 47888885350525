<template>
<div class="position-relative">

    <!-- <div class="uyuyuy">

    <div class="locale-changer">
    <select v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
        {{ lang }}
      </option>
    </select>
    <v-btn @click="ppp">click</v-btn>
  </div>

    {{ $t("ciao") }}
    <br>
    {{ $t("mesaggio") }}
    </div> -->
    <the-intro></the-intro>
    <latest-news></latest-news>
    <the-stats data-aos="fade-up"></the-stats>
    <the-catalogs ></the-catalogs>
    <redirect-page data-aos="fade-up"></redirect-page>
</div>
</template>

<script>
import TheIntro from "./sections/TheIntro.vue"
import LatestNews from "./sections/LatestNews.vue"
import TheStats from "./sections/TheStats.vue"
import TheCatalogs from "./sections/TheCatalogs.vue"
import RedirectPage from "./sections/RedirectPage.vue"

export default {
    components: {
        TheIntro,
        LatestNews,
        TheStats,
        TheCatalogs,
        RedirectPage,
    },
    data() {
        return {
            langs: ['it', 'en', 'de']
        }
    },
    methods: {
      ppp() {
        console.log("change");

      }
    },
}
</script>

<style lang="scss" scoped>
/*  */
.uyuyuy{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5555;
}
</style>