import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
    namespaced: true,
    state() {
        return {
            news : [
                {
                    id: 1,
                    title: 'titolo1',
                    date: '15/06/2548',
                    tags: ['tag1','tag2'],
                    text: 'testo 1 Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi fuga consequatur corrupti rerum accusamus optio autem quidem harum! Numquam, laborum? Autem excepturi aliquam laboriosam cum libero voluptas rerum soluta molestias!'
                },
                {
                    id: 2,
                    title: 'titolo2',
                    date: '25/06/2548',
                    tags: ['tag1'],
                    text: 'testo 1 Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi fuga consequatur corrupti rerum accusamus optio autem quidem harum! Numquam, laborum? Autem excepturi aliquam laboriosam cum libero voluptas rerum soluta molestias!'
                },
                {
                    id: 3,
                    title: 'titolo3',
                    date: '31/06/2548',
                    tags: ['tag1','tag2','tag3'],
                    text: 'testo 1 Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi fuga consequatur corrupti rerum accusamus optio autem quidem harum! Numquam, laborum? Autem excepturi aliquam laboriosam cum libero voluptas rerum soluta molestias!'
                },
                {
                    id: 4,
                    title: 'titolo4',
                    date: '11/06/2548',
                    tags: ['tag1','tag2','tag4'],
                    text: 'testo 1 Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi fuga consequatur corrupti rerum accusamus optio autem quidem harum! Numquam, laborum? Autem excepturi aliquam laboriosam cum libero voluptas rerum soluta molestias!'
                },
                {
                    id: 5,
                    title: 'titolo5',
                    date: '01/06/2548',
                    tags: ['tag1','tag2','tag5'],
                    text: 'testo 1 Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi fuga consequatur corrupti rerum accusamus optio autem quidem harum! Numquam, laborum? Autem excepturi aliquam laboriosam cum libero voluptas rerum soluta molestias!'
                },
            ],
        };
    },
    actions,
    mutations,
    getters,
};
