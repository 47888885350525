const TheModule = () => import("./TheModule.vue");
const PageJournals = () => import("./views/PageJournals");
const TheJournal = () => import("./views/TheJournal");
const EditJournal = () => import("./views/EditJournal");
const ThePeriodic = () => import("./views/ThePeriodic");
/* const EditCatalog = () => import("./views/EditCatalog.vue");
const CatalogStatic = () => import("./views/CatalogStatic.vue");
const CatalogDynamic = () => import("./views/CatalogDynamic.vue");
const PeriodicUpdate = () => import("./views/PeriodicUpdate.vue"); */

const moduleRoute = {
    path: '/journal',
    component: TheModule,
    children: [
         {
          path: '/',
          component: PageJournals,
          meta: {
            requiresAuthenticated: true
          },
        },
        {
          path: 'edit/:id',
          component: EditJournal,
          props: true,
          meta: {
            requiresAuthenticated: true
          },
        },
        {
          path: ':id',
          component: TheJournal,
          props: true,
          meta: {
            requiresAuthenticated: true
          }
        },
        {
          path: ':id/:periodicid',
          props: true,
          component: ThePeriodic,
          meta: {
            requiresAuthenticated: true
          },
        },
/*        {
          path: 'edit/:id',
          component: EditCatalog,
          props: true,
          meta: {
            requiresAuthenticated: true
          },
        },
        {
          path: 'static/:id',
          component: CatalogStatic,
          props: true,
          meta: {
            requiresAuthenticated: true
          },
        },
        {
          path: 'dynamic/:id',
          props: true,
          component: CatalogDynamic,
          meta: {
            requiresAuthenticated: true
          },
        },
        {
          path: 'periodic/:id/:periodicid',
          props: true,
          component: PeriodicUpdate,
          meta: {
            requiresAuthenticated: true
          },
        }, */
    ]
};

/* {
              path: ':id/periodic/:periodicId',
              props: true,
              component: PeriodicUpdate,
              meta: {
                requiresAuthenticated: true
              },
            }, */

export default router => {
  router.addRoutes([moduleRoute]);
};
