import 'aos/dist/aos.css'

import '@/assets/css/index.css'

import VueI18n from 'vue-i18n'

import Vue from "vue";
import App from "./App.vue";

import { registerModules } from "./register-modules";
import router from "./router";
import store from "./store";

import VSwitch from 'v-switch-case'
import AOS from 'aos'

library.add(
  faCalendar,
  faUser
)

/* COMPONENTI GLOBALI */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CountryFlag from 'vue-country-flag'
import CardUpdate from "@/components/CardUpdate.vue"

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('card-update', CardUpdate)
Vue.component('country-flag', CountryFlag)

/* MODULI */
import updates from "./modules/updates";
import news from "./modules/news";
import searchs from "./modules/searchs";
import countries from "./modules/countries";
import collections from "./modules/collections";
import journals from "./modules/journals";

/* import catalogs from "./modules/catalogs";
import notifications from "./modules/notifications"; */

registerModules({
  updates: updates,
  news: news,
  searchs: searchs,
  countries: countries,
  collections: collections,
  journals: journals,

  // catalogs: catalogs,
  // notifications: notifications,
});

/* MOMENT */
const moment = require('moment')

require('moment/locale/es')
moment.locale('it');
 
Vue.use(require('vue-moment'), {
    moment
})

import "@/plugins/mixins";

import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

import vuetify from './plugins/vuetify'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCalendar,
  faUser
} from '@fortawesome/free-solid-svg-icons'

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
  transition: "Vue-Toastification__fade",
  position: "top-right",
  timeout: 10000,
  closeOnClick: false,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: false,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
  maxToasts: 20,
  newestOnTop: true
});

Vue.use(VueI18n)

Vue.config.productionTip = false;
Vue.use(VSwitch)


function loadLocaleMessages() {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
        const matched = key.match(/([A-Za-z0-9-_]+)\//i);
        const matchedModule = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1 && matched && matchedModule.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
            const module = matchedModule[1];
            if (!messages[locale]) messages[locale] = {};
            messages[locale][module] = locales(key);
        }
    }
  });
  return messages;
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'it', // set locale,
  fallbackLocale: 'it',
  messages: loadLocaleMessages(),
})

new Vue({
  i18n,
  components: {
    ValidationProvider
  },
  router,
  store,
  vuetify,
  mounted() {
    AOS.init()
  },
  async created() {

    var myScrollFunc = function() {
      const myID = document.getElementById("hamburgerMenu");
      var y = window.scrollY;
      if (y >= 200) {
        myID.classList.add("show")
      } else {
        myID.classList.remove("show")
      }
    };

    window.addEventListener("scroll", myScrollFunc);

    await this.delay(50);
    this.mainHideLoading();
  },
  render: h => h(App)
}).$mount("#app");
