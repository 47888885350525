import Vue from "vue";
import VueRouter from "vue-router";

import PageHome from "./../pages/home/PageHome";
import PageAccount from "./../pages/account/PageAccount";
import PageAgenda from "./../pages/agenda/PageAgenda";
import PageFaq from "./../pages/faq/PageFaq";
import CollectionModule from "./../pages/collections/CollectionModule";
import PageCollections from "./../pages/collections/PageCollections";
import EditCollection from "./../pages/collections/EditCollection";
import TheCollection from "./../pages/collections/TheCollection";
import PageUpdates from "./../pages/updates/PageUpdates";

import LoginPage from "./../views/LoginPage";
import NotFound from "./../views/NotFound";

import store from "../store/index.js";


Vue.use(VueRouter);

const routes = [
  { path: '/', component: PageHome, meta: { requiresAuthenticated: true }},
  
  { path: '/account', component: PageAccount, meta: { requiresAuthenticated: true }},
  { path: '/agenda', component: PageAgenda, meta: { requiresAuthenticated: true }},
  { path: '/faq', component: PageFaq, meta: { requiresAuthenticated: true }},
  {
    path: '/collections',
    component: CollectionModule,
    meta: { requiresAuthenticated: true },
    children: [
      {
        path: '/',
        component: PageCollections,
        meta: {
          requiresAuthenticated: true
        },
      },
      {
        path: ':id',
        component: TheCollection,
        props: true,
        meta: {
          requiresAuthenticated: true
        },
      },
      {
        path: 'edit/:id',
        component: EditCollection,
        props: true,
        meta: {
          requiresAuthenticated: true
        },
      },
    ]
  },
  { path: '/updates', component: PageUpdates, meta: { requiresAuthenticated: true }},

  { path: '/login', component: LoginPage, meta: { requiresUnauthenticated: true } },

  { path: '*', component: NotFound },
];


const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { y: 0 }
  },
});

/* const messages = {
  en: {
    hello: 'hello world',
    message: 'message world'
  },
  it: {
    hello: '123',
    message: '456'
  }
} */



router.beforeEach((to, from, next) => {

  if ( to.meta.requiresUnauthenticated && store.getters.isAuthenticated ) {
    return false;
  } else if( to.meta.requiresAuthenticated && !store.getters.isAuthenticated ) {
    next('/login?target='+to.path);
  } else {
    next();
  }

})

export default router;
