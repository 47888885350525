<template>
   <div class="page-intro">
      <div class="blob-2"><img src="@/assets/img/texture/blob-2.png" alt="" /></div>
      <div class="blob-4"><img src="@/assets/img/texture/blob-1.png" alt="" /></div>

      <div class="container-max-md text-center text-container">

         <div class="flex flex-col align-center mb-2">
            <div v-if="icon" class="title-icon">
               <v-icon color="primary" x-large>{{icon}}</v-icon>
            </div>
            <h1 class="font-bold like-h2 color-primary w-[fit-content] p-[.3rem_5rem]" :class="{'title-border':icon}">{{title}}</h1>
         </div>
         <div class="intro-decoration mt-2 mb-4">
            <img src="@/assets/img/texture/intro-decoration.png">
         </div>
         <slot></slot>
      </div>
   </div>
</template>

<script>
export default {
   props:['title','icon']
};
</script>

<style lang="scss" scoped>
/*  */
.title-icon{
   height: 75px;
   width: 75px;
   transform: translateY(18px);
   padding: 1rem;
   border-radius: 100px;
   border: 2px solid $primary;
}
.title-border{
   position: relative;
   z-index: 5;
   background: white;
   border-top: 2px solid $primary;
   border-bottom: 2px solid $primary;
}

.intro-decoration{
   display: flex;
   justify-content: center;
   position: relative;

   img{
      position: relative;
      z-index: 2;
      padding: 0 1.5rem;
      background: white;
   }

   &::after{
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      height: 3px;

      background: linear-gradient(90deg, rgba($primary, 0) 2%, $primary 35%, $primary 75%, rgba($primary, 0) 98%);
   }
}
</style>