import Vue from "vue";
import { $api } from "@/services/api";
import moment from 'moment';

Vue.mixin({
  data() {
    return {
      tooltip_open_delay: 500, 
    }
  },
  computed: {
    $api: () => $api
  },
  methods: {

    delay: function(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },

    setLoading(new_value){
      this.$store.commit('setLoading',new_value)
    },
    mainHideLoading: function() {
      this.$store.commit('hideLoading')
    },
    mainShowLoading: function() {
      this.$store.commit('showLoading')
    },

    formatDate(date, formatDate = 'DD/MM/YYYY') {
      return moment(date).format(formatDate)
    }

  }
});