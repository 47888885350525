var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-home--stats py-section"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"container"},[_c('div',{staticClass:"grid grid-cols-12"},[_c('div',{staticClass:"col-span-12 lg:col-span-4"},[_c('h3',{staticClass:"font-bold text-primary mb-2"},[_vm._v(_vm._s(_vm.$t("home.graph.title")))]),_c('v-list',{staticClass:"v-list__stats"},[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selected_item),callback:function ($$v) {_vm.selected_item=$$v},expression:"selected_item"}},_vm._l((_vm.graphs_label),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1)],1)],1),_c('div',{staticClass:"col-span-12 lg:col-span-8"},[_c('div',{directives:[{name:"switch",rawName:"v-switch",value:(_vm.selected_item),expression:"selected_item"}],staticClass:"sm:ml-10 w-max-100"},[_c('div',{directives:[{name:"case",rawName:"v-case",value:(0),expression:"0"}]},[_c('histogram-country',{attrs:{"chartData":_vm.histogram_country_data,"height":50,"width":100}})],1),_c('div',{directives:[{name:"case",rawName:"v-case",value:(1),expression:"1"}]},[_c('line-tag',{attrs:{"chartData":_vm.line_tag_data}})],1),_c('div',{directives:[{name:"case",rawName:"v-case",value:(2),expression:"2"}]},[_c('h2',[_vm._v("2")])]),_c('div',{directives:[{name:"case",rawName:"v-case",value:(3),expression:"3"}]},[_c('h2',[_vm._v("3")])])])])])]),_c('div',{staticClass:"my-section",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"title--section mb-5"},[_vm._v(" "+_vm._s(_vm.$t("home.update.title"))+" ")]),_c('carousel',{staticClass:"carousel carousel--top-news",attrs:{"loop":false,"rewind":true,"dots":true,"nav":false,"margin":18,"autoplay":true,"autoplayHoverPause":true,"center":true,"stagePadding":0,"startPosition":1,"responsive":{
               0:{
                  items: 1,
               },
               840:{
                  items: 2,
               }
            }}},_vm._l((_vm.updates),function(update){return _c('div',{key:update.id},[_c('card-update',{staticClass:"mb-1",attrs:{"update":update}})],1)}),0)],1)]),_c('div',{staticClass:"my-section",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"container"},[_c('h3',{staticClass:"title--underline color-primary mb-5"},[_vm._v(" "+_vm._s(_vm.$t("home.entry-force.title"))+" ")]),_c('table-update',{staticClass:"mb-1",attrs:{"updates":_vm.updates_pass}})],1)]),_c('div',{staticClass:"container"},[_c('h3',{staticClass:"title--underline color-primary mb-5"},[_vm._v(" "+_vm._s(_vm.$t("home"))+" ")]),_c('div',{staticClass:"grid grid-cols-2 gap-10 mt-3 "},_vm._l((_vm.updates_out_subscription),function(update){return _c('div',{key:update.id,staticClass:"col-span-1 card-update--container__grid-mode"},[_c('card-update',{attrs:{"update":update,"checkbox_abilitate":false}})],1)}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blob-2"},[_c('img',{attrs:{"src":require("@/assets/img/texture/blob-2.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blob-4"},[_c('img',{attrs:{"src":require("@/assets/img/texture/blob-1.png"),"alt":""}})])}]

export { render, staticRenderFns }