<template>
<div class="relative container">
    <a class="icon-hover link-back" @click="backToColletions">
        <v-icon>mdi-arrow-left-thin</v-icon>
        <span class="inline-block ml-1">Torna alle collezioni</span>
    </a>
    <div v-if="copy_catalog.id" class="my-section">

        <div>
            <v-switch
                v-model="copy_catalog.active"
                :label="copy_catalog.active ? 'Attivo' : 'Non attivo'"
            ></v-switch>
        </div>

        <div class="form-group">
            <v-text-field
              class="form-group--input"
              label="Nome catalogo"
              v-model="copy_catalog.title"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
        </div>
        <div class="form-group">
            <v-textarea
              class="form-group--input"
              label="Descrizione"
              v-model="copy_catalog.description"
              outlined
              dense
              hide-details="auto"
            ></v-textarea>
        </div>
        <div class="my-5">

            <div class="flex justify-end gap-3 mt-5">
                <v-btn
                    class="color-white"
                    color="green"
                    elevation="2"
                    @click="saveCatalog"
                >
                    <v-icon left>
                        mdi-content-save
                    </v-icon>
                    Salva
                </v-btn>
                <v-btn
                    class="color-white"
                    color="red"
                    elevation="2"
                    to="/catalogs"
                >
                    <v-icon left>
                        mdi-cancel
                    </v-icon>
                    Annulla
                </v-btn>
            </div>
        </div>
    </div>
    <div v-else class="my-section">Questo catalogo non esiste</div>

</div>
</template>

<script>
// import moment from 'moment';

export default {
    props: ['id'],

    data() {
        return {
            error_date: '',
            copy_catalog: null,

        }
    },
    computed: {
        /* countries() {
            return this.$store.getters['countries/selectableCountries']
        },
        dateStartMoment () {
            return this.date_start ? moment(this.date_start).format('DD/MM/YYYY') : ''
        },
        dateEndMoment () {
            return this.date_end ? moment(this.date_end).format('DD/MM/YYYY') : ''
        },
        abilitateSaveForStatic(){
            this.setErrorDate("")
            if(this.copy_catalog.check_type_selected || this.check_type_selected!= 'static'){
                return false
            }
            if(this.date_end < this.date_start || this.date_end == this.date_start) {
                this.setErrorDate("La data di fine è antecedente a quella di inizio")
                return true
            }
            let t = !this.date_start || !this.date_end
            return t
        } */
    },
    methods: {
        /* setErrorDate(text){
            this.error_date = text
        }, */
        refresh(selected){
            console.log("second",selected);
            this.copy_catalog.category = selected.selected_category
            this.copy_catalog.countries = selected.selected_countries
            this.copy_catalog.parametri = selected.selected_parametri
        },
        async backToColletions() {
            this.$router.replace('/collections');

            await this.delay(200);
            let element = document.getElementById('anchorCollections')
            element.scrollIntoView({ behavior: 'smooth', block: 'start'});
        },
        saveCatalog(){

            this.setLoading(true)
            this.$store.dispatch('collections/editCollection',this.copy_catalog)

        }
    },
    created () {
        console.log("this.id",this.id);
        this.copy_catalog = this.$store.getters['collections/collection'](this.id);
        console.log("this.copy_catalog",this.copy_catalog);
        if(!this.copy_catalog.type){
            this.check_type_selected = this.copy_catalog.type
        }
    },
}
</script>

<style lang="scss" scoped>
/*  */
</style>