<template>
<div class="position-relative">
    <intro-pages :icon="'mdi-star'" :title="'My collection'">
        <p>
            In questa area visualizzi la lista degli aggiornamenti normativi (o pre-regolatori) da te contrassegnati come preferiti. Per rimuoverli é sufficiente riaprire l’aggiornamento e selezionare nuovamente il simbolo “stella”.
        </p>
    </intro-pages>
    <div id="anchorCollections" ></div>
    <list-collections></list-collections>
</div>
</template>

<script>
import IntroPages from "@/components/IntroPages.vue"
import ListCollections from "@/modules/collections/views/ListCollections.vue"


export default {
    components: {
        IntroPages,
        ListCollections,
    },
    data() {
        return {
        }
    },
    computed: {
    },
}
</script>

<style lang="scss" scoped>
/*  */
</style>