export default {
    setAutoLogout(state) {
      state.did_auto_logout = true;
    },
    setAuthprofile(state, payload) {
        if( payload == null ) {
            state.authenticated_profile = null;    
        } else {
            state.authenticated_profile = {
                profile_id: payload.profileid,
                name: payload.name,
                surname: payload.surname,
                role: payload.role,
                token: payload.token,
                tokenDuration: payload.token_duration
            };
            state.did_auto_logout = false;
        }

    },
  
    setAclMap(state, map){
        state.acl_map = map;
    },
    setLoading(state,new_value){
        state.loadingPage = new_value;
    },
    hideLoading(state){
        state.loadingPage = false;
    },
    showLoading(state){
        state.loadingPage = true;
    },
    replaceItem(state, new_value){
        state.user = new_value
      },
      languagesChange(state){
          console.log("dentro");
        state.languages = {
            en: {
              ciao: 'AAAhello world',
              mesaggio: 'AAAmessage world'
            },
            it: {
              ciao: 'AAAciao',
              mesaggio: 'AAAmesaggio'
            }
          }
      },
  };