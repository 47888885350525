var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.updates && _vm.updates.length)?_c('div',{staticClass:"updates-table"},[_c('div',{staticClass:"flex justify-between align-center gap-5 w-100 p-[1rem_2rem] updates-table--row color-primary font-bold"},[_c('div',{staticClass:"grow"},[_vm._v(_vm._s(_vm.$t("table.nome"))+":")]),_c('div',{staticClass:"w-[250px] max-w-[40%]"},[_vm._v(_vm._s(_vm.$t("table.categorie"))+":")]),_c('div',{staticClass:"w-[130px] max-w-[40%]"},[_vm._v(_vm._s(_vm.$t("table.data-uscita"))+":")])]),_vm._l((_vm.updates),function(update){return _c('div',{key:update.id,staticClass:"flex justify-between align-center gap-[1.5rem] w-100 p-[1rem_2rem] updates-table--row"},[_c('div',{staticClass:"grow"},[_c('modal-update',{attrs:{"type":'table',"update":update}})],1),_c('div',{staticClass:"flex gap-2 w-[250px] max-w-[40%]"},[_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"tag-icon bg-red color-white",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("pencil")])])],1),_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"tag-icon bg-green color-white",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-barley")])]}}],null,true)},[_c('span',[_vm._v("barley")])])],1),_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"tag-icon bg-blue-500 color-white",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-home")])]}}],null,true)},[_c('span',[_vm._v("home")])])],1)]),_c('div',{staticClass:"w-[130px] max-w-[40%]"},[_vm._v(" "+_vm._s(_vm._f("moment")([update.date],"DD/MM/YYYY"))+" ")])])})],2):_c('div',[_vm._v(" non ci sono update ")])}
var staticRenderFns = []

export { render, staticRenderFns }