<template>
  <div id="mineFoooter" class="">
    <section class="footer ">

    <div class="container">

       <div class="row justify-between">
            <div class="col-span-12 col-span-md-6 order-2 order-md-1 footer--col footer--col__1">

                <div class="footer--information">
                    <h4 class="footer--undertitle  text-center text-md-left">Need more information?</h4>
                    <p class="color-blue mt-3">CONTACT US!</p>
                    <a class="footer--link">
                        <i class="far fa-envelope"></i>
                        regulatory.services@mxns.com
                    </a>
                    <a class="footer--link">
                        <i class="fas fa-phone-alt"></i>
                        +39 045 82 83 311
                    </a>
                </div>

                <div class="footer--sx">
                    <small class="footer--small">Find out more about the Group</small>
                    <img class="footer--logo" src="@/assets/img/logo.png" alt="">
                    <small class="footer--small">www.merieuxnutrisciences.it</small>
                </div>

            </div>
            <div class="col-span-12 col-span-md-6 order-1 order-md-2  footer--col footer--col__2">
                <h4 class="footer--undertitle text-center text-md-right ">Need more information?</h4>
                <div class="footer--optin">
                  
                  <div class="form-group">
                    <v-text-field
                      class="form-group--input"
                      label="Email"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </div>
                  <div class="form-group">
                    <v-text-field
                      class="form-group--input"
                      label="Name"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </div>
                  <div class="form-group">
                    <v-text-field
                      class="form-group--input"
                      label="Phone"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </div>
                  <div class="form-group">
                    <v-textarea
                      class="form-group--input"
                      label="Request..."
                      outlined
                      dense
                      hide-details="auto"
                    ></v-textarea>
                  </div>


                    <div class="form-group d-flex justify-end">
                      <v-checkbox
                      style="font-size: 11px"
                        label="I accept privacy policy"
                      ></v-checkbox>
                    </div>

                    <small class="footer--small text-left text-md-right">
                    The personal data you mentioned will be processed by Chelab S.r.l. - Socio Unico, data controller, to answer to your request. Your data may be transferred to one of the affiliated entities of Chelab S.r.l. - Socio Unico located or not within the EU, which will process them in accordance with your content request.

                    You have the right to access to your data, request rectification, erasure, restriction of processing or withdrawal and may exercise this right by sending your request by email to contact.italy@mxns.com.                    </small>

                    <div class="text-left text-md-right">
                        <a class="footer--sub" href="#">SUBMIT</a>
                    </div>

                </div>

            </div>
        </div>

    </div>
    

</section>
<section class="bottom-bar">
    <div class="container">
        <div class="bottom-bar--small">Expert Services - Chelab S.r.l. Socio Unico</div>
        <div class="bottom-bar--small">Company subject to the direction and coordination of Mérieux NutriSciences Corporation</div>
        <div class="bottom-bar--small">
            Head Office Via Fratta 25, 31023 Resana, Italy T: +39 045.8283311 • F: +39 045.8283345 • 
            <a class="footer--link d-inline">regulatory.services@mxns.com</a> • 
            VAT nr. IT01500900269, R.E.A Treviso n. 156079 Fully paid up € 103.480,00 
            <a href="https://regulatory.mxns.com/en/privacy-policy" target="_blank" class="footer--link d-inline">Legal Mentions &amp; Privacy Notice</a>
            © Mérieux NutriSciences, 2022 All Rights Reserved
        </div>
    </div>
</section>
  </div>
</template>

<script>
  export default {
    data () {
      return {
      }
    },
  }
</script>

<style lang="scss" scoped>
/*  */
.footer{
    box-shadow: inset 0px 5px 3px rgba(black, .15);
    padding: 3rem 0;


    &--undertitle{
        color: $primary;
    }

    &--information{
    }

    &--link{
        display: block;
        color: #666;
        cursor: pointer;
        &:hover{color: $primary;}
    }

    &--small{
        display: block;
        &__right{
            text-align: right;
        }
    }

    &--logo{
        height: 35px;
        width: auto;
    }

    &--optin{
        max-width: 350px;
        margin-left: auto;
        @media screen and (max-width: $M) {
            max-width: 100%;
        }
    }

    &--sub{
        display: inline-block;
        text-transform: uppercase;
        margin-top: 1rem;
        padding: .5rem 1.4rem;
        border-radius: 5px;
        background-color: $primary;
        color: $white;

        transition: background-color .3s;

        &:hover{
            background-color: $primary;
            color: $white;
        }

    }


    &--col{

        &__1{
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            margin-top: 0;
            @media screen and (max-width: $M) {
                margin-top: 3rem;
                text-align: center;
            }
        }
        &__2{
        }

    }

}

.bottom-bar{

    background-color: #E5E5E4;
    padding: 2rem 0;

    &--small{
        font-size: 12px;
        text-align: center;
    }

}
</style>

