export default {
    updates(state){
        let t = state.updates
        
        if(t){
            return t
        }

        return {id:0, value:'errore ID non trovato in profileS'}
    },
    updatesNumber: (state) => (number) => {
        let t = []
        for(let i = 0; i < number; i++){
            t.push(state.updates[i])
        }
        return t
    },
    updates0(){
        return []
    },
    update: (state) => (id) => {

        
        let t = state.updates.find(item => item.id == id)
        if(t){
            return JSON.parse(JSON.stringify(t))
        }
        return {id:0, value:'errore ID non trovato in profileS'}
        
        

    }
};