<template>
<div class="position-relative">
    <h1>faq</h1>
</div>
</template>

<script>

export default {
    components: {
    },
}
</script>

<style lang="scss" scoped>
/*  */
</style>