<template>
  <v-app>
    
      <!-- CANCELLARE -->
      <!-- <div class="btns-to-cancell">
        <v-btn class="btn-to-cancell" @click="showLoading">Loading</v-btn>
        <v-btn class="btn-to-cancell" :to="'/faq'">Faq</v-btn>
      </div> -->
      <!-- CANCELLARE -->

    <loader-frame></loader-frame>
    <v-main>


      <the-header v-if="isAuthenticated"></the-header>
      <main-stage></main-stage>
      <hamburger-menu
        v-if="isAuthenticated"
      ></hamburger-menu>
      <the-footer v-if="isAuthenticated"></the-footer>
    </v-main>
  </v-app>
</template>

<script>
import LoaderFrame from "./views/LoaderFrame.vue";
import HamburgerMenu from "@/components/HamburgerMenu.vue";
  import TheHeader from "./views/TheHeader.vue";
  import MainStage from "./views/MainStage.vue";
  import TheFooter from "./views/TheFooter.vue";
  export default {
    components:{
      TheHeader,
      TheFooter,
      MainStage,
      LoaderFrame,
      HamburgerMenu
    },
    data(){
      return{
        colSize: 3,
        isAuthenticated: false
      }
    },
    computed: {
      didAutoLogout() {
        return this.$store.getters.did_auto_logout;
      },
      didAutoLogin() {
        return this.$store.getters.isAuthenticated;
      }
    },
    async created() {
      try {
        await this.$store.dispatch('tryLogin');
        const redirectUrl = this.$route.query.target ?? '/';
        this.$router.replace(redirectUrl);
      } catch (err) {
        console.log(err.message || 'Failed to authenticate.');
      }
    },
    watch: {
      didAutoLogout(curValue, oldValue) {
        if (curValue && curValue !== oldValue) {
          this.$router.replace('/login');
        }
      },
      didAutoLogin(curValue, oldValue) {
        this.isAuthenticated = curValue;

        if (!curValue && curValue !== oldValue) {
          this.$router.replace('/login');
        }
      },
    },
    methods:{
      transformSidebar(){
        if(this.colSize == 3){
          this.colSize = 1;
        } else {
          this.colSize = 3;
        }
      },

      /* CANCELLARE */
      showLoading() {
        this.mainShowLoading()
      },
      /* CANCELLARE */
    }
  }
</script>

<style lang="scss">
    .v-main__wrap{
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      & > *:nth-child(2){
        flex-grow: 1;
      }
    }

    .container-allert{
      display: block;
      position: fixed;
      bottom: 10px;
      right: 10px;
      z-index: 100;
    }

    /* CANNCELLARE */
    .btns-to-cancell{
      position: fixed;
      top: 2px;
      left: 2px;
      z-index: 20000;
    }
    .btn-to-cancell{
        opacity: .1;

      &:hover{
        opacity: 1;
      }
    }
    /* CANNCELLARE */
</style>
