<template>
<div class="header">
   <div class="shadow-md z-30">
      <div class="container flex justify-between align-center gap-10 py-2">
         <div class="flex gap-10">
            <router-link to="/">
               <img class="max-h-[55px]" src="@/assets/img/logo.png"/>
            </router-link>
         </div>
         <div>
            <h4 class="font-bold color-primary">Visit our Food Compliance Solutions website</h4>
         </div>
      </div>
   </div>
   <div id="containerRef" class="container flex justify-around links-header bg-transparent">
      <router-link
         v-for="link in links"
         :key="link.title"
         :to="link.link"
         class="links-header--link"
      >
         <v-icon large>{{ link.icon }}</v-icon>
         <span>{{link.title}}</span>
      </router-link>
   </div>
</div>
</template>

<script>
export default {
   data() {
      return {
         links: [
            { title: "home", icon: "mdi-home", link: "/" },
            { title: "updates", icon: "mdi-newspaper-variant-multiple-outline", link: "/updates" },
            { title: "ricerca", icon: "mdi-text-search", link: "/search" },
            { title: "notifiche", icon: "mdi-bell-outline", link: "/journal" },
            { title: "collection", icon: "mdi-star-outline", link: "/collections" },
            { title: "paesi", icon: "mdi-earth", link: "/countries" },
            { title: "account", icon: "mdi-account-outline", link: "/account" },
         ],
      };
   },
   computed: {
      link_active() {
         return this.$route.path 
      },
      active_color(){
         let temp = this.links.find(link => link.link == this.link_active)

            if(temp){
               temp = temp.color_hover
            } else {
               temp = "primary"
            }
            return temp
      },
   },
};
</script>

<style lang="scss" scoped>
/*  */
.header {
   position: relative;
   z-index: 10;
}

.links-header{

   padding-top: 3rem;
   padding-bottom: 3rem;

   &--link{
      display: flex;
      flex-direction: column;
      color: $black;
      text-transform: uppercase;
      .v-icon{
         color: $black;
         margin-bottom: 1rem;
      }

      &:hover{
         color: $hover;
         .v-icon{
            color: $hover;
         }
      }

      &.router-link-exact-active{
         color: $secondary;
         .v-icon{
            color: $secondary;
         }
      }
   }
}

</style>

