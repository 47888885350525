<template>
  <v-dialog
      :persistent="true"
      v-model="dialog"
      width="40vw"
      content-class=""
  >

    <v-dialog
      :persistent="true"
      v-model="dialog_conferm"
      width="500"
    >

      <v-card>
          <v-card-title class="bg-green color-white text-h4 mb-5 pb-4">
            Confermato
          </v-card-title>

          <v-card-text>
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Assumenda, aspernatur vitae praesentium excepturi est accusantium numquam, sint, earum officia ab id error! Alias minus autem vero, inventore voluptate hic? Quae!
            </p>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="flex justify-end">
            <div>
                <v-btn
                  text
                  @click="dialog_conferm = false"
                >
                  OK
                </v-btn>
            </div>
          </v-card-actions>
      </v-card>
    </v-dialog>

    <template v-slot:activator="{ on, attrs }">
      <a
        v-bind="attrs"
        v-on="on"
        class="modal-update--control modal-update--control__catalogo"
      >
          <v-icon>mdi-book-multiple</v-icon>
          Aggiungi a una collezione
      </a>
    </template>

    <div class="modal-colletcions relative flex flex-column gap-[15px] p-[3.5rem]">
      <a
        @click="dialog = false"
        class="modal-close-icon"
      >
        <v-icon>mdi-close-circle-outline</v-icon>
      </a>

      <div>
        <h4 class="color-primary font-bold">Seleziona un catalogo {{value}}</h4>
      </div>
      <div class="grow flex flex-col justify-end">
        <div class="form-group">
          <v-select
            v-model="value"
            :items="collections"
            item-text="title"
            item-value="id"

            hide-selected
          >
          </v-select>
        </div>
      </div>
      <div class="flex justify-end gap-5 mt-5">
        <v-btn
          @click="addToCollection"
          color="primary"
        > 
          Continua
        </v-btn>
        <v-btn
          color="red"
          text
          @click="dialog = false"
        >
          Annulla
        </v-btn>
      </div>
    </div>

  </v-dialog>
    
</template>

<script>
  // import moment from 'moment';
  export default {
    props: ["update"],
    data () {
      return {
        dialog: false,
        dialog_conferm: false,
        value: '',
        collection_selected: null
      }
    },
    computed: {
      collections(){
        let collections = this.$store.getters["collections/collections"];
        return collections
      },
    },
    methods: {
      async addToCollection() {
        let params = {
          update_id: this.update.id,
          collection_id: this.value,
        }
        this.setLoading(true);
        await this.$store.dispatch("collections/addUpdate",params);
        this.dialog = false
        this.dialog_conferm = true
      }
    },
  }
</script>

<style lang="scss">

  .modal-colletcions{
    background: white;
    min-height: 100%;
    max-height: 100%;
    overflow-y: scroll;
  }
  
</style>