export default {
    collections(state){
        let t = state.collections
        if(t){
            return t
        }
        return {id:0, value:'errore ID non trovato in profileS'}
    },
    collection: (state) => (id) => {
        let t = state.collections.find(item => item.id == id)
        if(t){
            return JSON.parse(JSON.stringify(t))
        }
        return {id:0, value:'errore ID non trovato in profileS'}
    },
};