import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
    namespaced: true,
    state() {
        return {
            framework_info: [
                {
                    id: 1,
                    title: "1 titolo framework",
                    text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque porro nobis commodi, explicabo eaque sit cupiditate consectetur aliquid debitis quas fuga cum voluptas harum, libero, alias magnam perspiciatis veritatis aspernatur!",
                    last_updates: "2022-06-16",
                    table: [
                        {
                            regulatory_reference: 'abcdefg1',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg2',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg3',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg4',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg5',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg6',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                    ],
                },
                {
                    id: 2,
                    title: "2 titolo framework",
                    text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque porro nobis commodi, explicabo eaque sit cupiditate consectetur aliquid debitis quas fuga cum voluptas harum, libero, alias magnam perspiciatis veritatis aspernatur!",
                    last_updates: "2022-06-16",
                    table: [
                        {
                            regulatory_reference: 'abcdefg1',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg2',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg3',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg4',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg5',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg6',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                    ],
                },
                {
                    id: 3,
                    title: "4 titolo framework",
                    text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque porro nobis commodi, explicabo eaque sit cupiditate consectetur aliquid debitis quas fuga cum voluptas harum, libero, alias magnam perspiciatis veritatis aspernatur!",
                    last_updates: "2022-06-16",
                    table: [
                        {
                            regulatory_reference: 'abcdefg1',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg2',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg3',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg4',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg5',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg6',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                    ],
                },
                {
                    id: 4,
                    title: "4titolo framework",
                    text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque porro nobis commodi, explicabo eaque sit cupiditate consectetur aliquid debitis quas fuga cum voluptas harum, libero, alias magnam perspiciatis veritatis aspernatur!",
                    last_updates: "2022-06-16",
                    table: [
                        {
                            regulatory_reference: 'abcdefg1',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg2',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg3',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg4',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg5',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg6',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                    ],
                },
                {
                    id: 5,
                    title: "5titolo framework",
                    text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque porro nobis commodi, explicabo eaque sit cupiditate consectetur aliquid debitis quas fuga cum voluptas harum, libero, alias magnam perspiciatis veritatis aspernatur!",
                    last_updates: "2022-06-16",
                    table: [
                        {
                            regulatory_reference: 'abcdefg1',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg2',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg3',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg4',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg5',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg6',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                    ],
                },
                {
                    id: 6,
                    title: "6titolo framework",
                    text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque porro nobis commodi, explicabo eaque sit cupiditate consectetur aliquid debitis quas fuga cum voluptas harum, libero, alias magnam perspiciatis veritatis aspernatur!",
                    last_updates: "2022-06-16",
                    table: [
                        {
                            regulatory_reference: 'abcdefg1',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg2',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg3',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg4',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg5',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg6',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                    ],
                },
                {
                    id: 7,
                    title: "7titolo framework",
                    text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque porro nobis commodi, explicabo eaque sit cupiditate consectetur aliquid debitis quas fuga cum voluptas harum, libero, alias magnam perspiciatis veritatis aspernatur!",
                    last_updates: "2022-06-16",
                    table: [
                        {
                            regulatory_reference: 'abcdefg1',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg2',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg3',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg4',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg5',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg6',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                    ],
                },
                {
                    id: 8,
                    title: "8titolo framework",
                    text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque porro nobis commodi, explicabo eaque sit cupiditate consectetur aliquid debitis quas fuga cum voluptas harum, libero, alias magnam perspiciatis veritatis aspernatur!",
                    last_updates: "2022-06-16",
                    table: [
                        {
                            regulatory_reference: 'abcdefg1',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg2',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg3',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg4',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg5',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg6',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                    ],
                },
                {
                    id: 9,
                    title: "9titolo framework",
                    text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque porro nobis commodi, explicabo eaque sit cupiditate consectetur aliquid debitis quas fuga cum voluptas harum, libero, alias magnam perspiciatis veritatis aspernatur!",
                    last_updates: "2022-06-16",
                    table: [
                        {
                            regulatory_reference: 'abcdefg1',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg2',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg3',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg4',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg5',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg6',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                    ],
                },
                {
                    id: 10,
                    title: "10titolo framework",
                    text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque porro nobis commodi, explicabo eaque sit cupiditate consectetur aliquid debitis quas fuga cum voluptas harum, libero, alias magnam perspiciatis veritatis aspernatur!",
                    last_updates: "2022-06-16",
                    table: [
                        {
                            regulatory_reference: 'abcdefg1',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg2',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg3',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg4',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg5',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                        {
                            regulatory_reference: 'abcdefg6',
                            link: "https://www.google.com/",
                            date: "2022-02-16",
                        },
                    ],
                }
            ],
            countries: [
                {
                    id: 1,
                    name: 'italia',
                    label: 'Italia',
                    iso_code: 'it',
                    continent: 'europe',
                    last_update: '200',
                    special_country: 'sono un paese con info in più ITALIA',
                    framework_info: [1,2,3,4,5,6,7,8,9,10]
                },
                {
                    id: 2,
                    name: 'germania',
                    label: 'Germania',
                    iso_code: 'de',
                    continent: 'europe',
                    last_update: '20',
                    special_country: null,
                    framework_info: [4,2,5]
                },
                {
                    id: 3,
                    name: 'francia',
                    label: 'Francia',
                    iso_code: 'fr',
                    continent: 'europe',
                    last_update: '20',
                    special_country: null,
                    framework_info: [1,2,3]
                },
                {
                    id: 4,
                    name: 'usa',
                    label: 'Usa',
                    iso_code: 'usa',
                    continent: 'america',
                    last_update: '20',
                    special_country: 'sono un paese con info in più USA',
                    framework_info: [1,2,3]
                },
                {
                    id: 5,
                    name: 'brasile',
                    label: 'Brasile',
                    iso_code: 'bra',
                    continent: 'america',
                    last_update: '20',
                    special_country: null,
                    framework_info: [1,2,3]
                },
                {
                    id: 6,
                    name: 'cina',
                    label: 'Cina',
                    iso_code: 'chn',
                    continent: 'asia',
                    last_update: '20',
                    special_country: 'sono un paese con info in più CINA',
                    framework_info: [1,2,3]
                },
                {
                    id: 7,
                    name: 'argentina',
                    label: 'Argentina',
                    iso_code: 'arg',
                    continent: 'america',
                    last_update: '20',
                    special_country: null,
                    framework_info: [1,2,3]
                },
                {
                    id: 8,
                    name: 'malesia',
                    label: 'Malesia',
                    iso_code: 'mys',
                    continent: 'asia',
                    last_update: '20',
                    special_country: null,
                    framework_info: [1,2,3]
                },
                {
                    id: 9,
                    name: 'peru',
                    label: 'Perù',
                    iso_code: 'per',
                    continent: 'america',
                    last_update: '20',
                    special_country: null,
                    framework_info: [1,2,3]
                },
                {
                    id: 10,
                    name: 'svizzera',
                    label: 'Svizzera',
                    iso_code: 'che',
                    continent: 'europe',
                    last_update: '20',
                    special_country: null,
                    framework_info: [1,2,3]
                },
            ],
            continents:[
                {
                    id: 1,
                    name: "europe",
                },
                {
                    id: 2,
                    name: "america",
                },
                {
                    id: 3,
                    name: "asia",
                },
                {
                    id: 4,
                    name: "africa",
                },
                {
                    id: 5,
                    name: "oceania",
                },
            ],
        };
    },
    mutations,
    getters,
    actions,
};
