<template>
<div class="relative container">
    <a class="icon-hover link-back" @click="backToCollections">

        <v-icon>mdi-arrow-left-thin</v-icon>
        <span class="inline-block ml-1">Torna ai collezioni</span>
    </a>
    <div class="my-section">
        <div class="intro-collection py-section">
            <h1 class="intro-collection--title text-center font-bold color-primary mb-2">{{collection.title}}</h1>
            <p class="text-center">
                {{collection.description}}
            </p>
        </div>
        <div class="my-section">
            <list-updates
                :collection="collection"
                :passed_update="updates"
            ></list-updates>
        </div>
    </div>
</div>
</template>

<script>
import ListUpdates from "@/components/ListUpdates.vue"

export default {
    props: ['id'],
    components: {
        ListUpdates,
    },
    computed: {
        collection(){
            let t = this.$store.getters['collections/collection'](this.id);
            console.log("id",this.id);
            console.log("t",t);
            return t
        },
        updates() {
            let t = [];
            this.collection.updates.forEach(id => {
                t.push(this.$store.getters['updates/update'](id));
            })
            return t
        }
    },
    methods: {
        async backToCollections() {
            this.$router.replace('/collections');

            await this.delay(200);
            let element = document.getElementById('anchorCollections')
            element.scrollIntoView({ behavior: 'smooth', block: 'start'});
        }
    },
}
</script>

<style lang="scss" scoped>
/*  */
.link-back{
    position: absolute;
    top: 1rem;
    left: 1rem;

}
</style>